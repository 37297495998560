import { serviceUrl, subServiceUrl } from 'src/utils/constants';
import axiosClient from '../axiosClient';

const serviceApi = {
  getAllService(params) {
    const url = `/${serviceUrl.getAllService}`;
    return axiosClient.get(url, { params });
  },
  getAllServiceWithoutParams(params) {
    const url = `/${serviceUrl.getAllServiceWithoutParams}`;
    return axiosClient.get(url, { params });
  },
  postService(data) {
    const url = `/${serviceUrl.postService}`;
    return axiosClient.post(url, data);
  },
  deleteService(id) {
    const url = `/${serviceUrl.deleteService}/${id}`;
    return axiosClient.delete(url);
  },
  updateService({ id, data }) {
    const url = `/${serviceUrl.putService}/${id}`;
    return axiosClient.put(url, data);
  },
  getDetailService(id) {
    const url = `/${serviceUrl.detailService}/${id}`;
    return axiosClient.get(url);
  },
  updateSubServices(params) {
    const { _id, ...restParams } = params;
    const url = `${serviceUrl.putService}/${_id}/${subServiceUrl.basic}`;
    return axiosClient.put(url, restParams);
  },
};

export default serviceApi;
