import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

// scss
import styles from './style.module.scss';

// Others
import { digLogAction } from 'src/features/digLog/slice';

//utils
import { contentDialog } from 'src/utils/contentDialog';
import { IMAGE_TYPE_REGEX, MAX_FILE_SIZE } from 'src/utils/constants';

//import image
import iconClose from 'src/assets/icons/ic_close.svg';
import iconUpload from 'src/assets/icons/ic_upload.svg';

const ImagePicker = (props) => {
  const { image, readOnly, onChange, isLoading, disabled } = props;

  const cx = classNames.bind(styles);
  const dispatch = useDispatch();

  const processImage = (e) => {
    const file = e.target.files[0];

    if (!file?.name || !file.name.match(IMAGE_TYPE_REGEX)) {
      dispatch(digLogAction.getTitle({ title: contentDialog.IMAGE.NOT_FILE_IMAGE }));

      return false;
    }

    if (file?.size > MAX_FILE_SIZE) {
      dispatch(digLogAction.getTitle({ title: contentDialog.IMAGE.EXCEED_SIZE }));
      return false;
    }

    onChange && onChange(file);
  };

  return (
    <div className={cx('pickerImageGroup')}>
      {image ? (
        <>
          <img src={image} alt='Avatar' className={cx('img')} />
          {!readOnly && !disabled && (
            <img
              src={iconClose}
              alt=''
              className={cx('editInputPicker')}
              onClick={() => {
                onChange && onChange(null);
              }}
            />
          )}
        </>
      ) : (
        <label for='inputPickerImage' className={cx('labelInputPiacker', `${isLoading && 'loading'}`)}>
          <input
            id='inputPickerImage'
            type='file'
            className={cx('inputPickerImage')}
            accept='image/*'
            onChange={processImage}
            disabled={isLoading || disabled}
          />

          {isLoading ? (
            <div className={cx('spinner')}></div>
          ) : (
            <img src={iconUpload} alt='' className={cx('uploadIcon')} />
          )}
        </label>
      )}
    </div>
  );
};

export default ImagePicker;
