import { call, put, takeLatest } from 'redux-saga/effects';
import serviceApi from 'src/apis/superAdmin/serviceApi';
import imageApi from 'src/apis/superAdmin/imageApi';
import { serviceActions } from './slice';

function* handleGetAllService(action) {
  try {
    const response = yield call(serviceApi.getAllService, action.payload);
    const data = response.data.data;
    yield put(serviceActions.getAllServiceSuccess(data));
  } catch (err) {
    yield put(serviceActions.getAllServiceFail(err.message));
  }
}

function* handleGetAllServiceWithoutParams(action) {
  try {
    const response = yield call(serviceApi.getAllServiceWithoutParams, action.payload);
    const data = response.data.data;
    yield put(serviceActions.getAllServiceWithoutParamsSuccess(data));
  } catch (err) {
    yield put(serviceActions.getAllServiceWithoutParamsFail(err.message));
  }
}

function* handleCreateService(action) {
  try {
    const image = yield call(imageApi.postImage, action.payload.fileUrl);
    const response = yield call(serviceApi.postService, { ...action.payload.serviceInput, img: image.data.data.link });
    yield put(serviceActions.createServiceSuccess(response));
  } catch (err) {
    yield put(serviceActions.createServiceFail(err.message));
  }
}

function* handleDeleteService(action) {
  try {
    const response = yield call(serviceApi.deleteService, action.payload);
    yield put(serviceActions.deleteServiceSuccess(response));
  } catch (err) {
    yield put(serviceActions.deleteServiceFailed(err.message));
  }
}

function* handleUpdateService(action) {
  try {
    const image = action.payload.fileUrl === 1 ? null : yield call(imageApi.postImage, action.payload.fileUrl);
    const response = yield call(serviceApi.updateService, {
      id: action.payload.id,
      data: {
        ...action.payload.data,
        img: action.payload.fileUrl === 1 ? action.payload.imgUpdate : image?.data?.data?.link,
      },
    });
    yield put(serviceActions.updateServiceSuccess(response));
  } catch (err) {
    yield put(serviceActions.updateServiceFail(err.message));
  }
}

function* handleGetDetailService(action) {
  try {
    const response = yield call(serviceApi.getDetailService, action.payload);
    const data = response.data.data;
    yield put(serviceActions.getDetailServiceSuccess(data));
  } catch (err) {
    yield put(serviceActions.getDetailServiceFail(err.message));
  }
}

function* handleUpdateSubServices(action) {
  try {
    const { data } = yield call(serviceApi.updateSubServices, action.payload);
    yield put(serviceActions.updateSubServicesSuccess(data));
  } catch (error) {
    yield put(serviceActions.updateSubServicesFail(error.response.data.message));
  }
}

export default function* serviceSaga() {
  yield takeLatest(serviceActions.getAllService.type, handleGetAllService);
  yield takeLatest(serviceActions.getAllServiceWithoutParams.type, handleGetAllServiceWithoutParams);
  yield takeLatest(serviceActions.createService.type, handleCreateService);
  yield takeLatest(serviceActions.deleteService.type, handleDeleteService);
  yield takeLatest(serviceActions.updateService.type, handleUpdateService);
  yield takeLatest(serviceActions.getDetailService.type, handleGetDetailService);
  yield takeLatest(serviceActions.updateSubServices.type, handleUpdateSubServices);
}
