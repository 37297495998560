import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Slice
import {
  subServiceActions,
  selectListSubServiceNotCombine,
  selectSubServiceFilter,
} from 'src/features/superAdmin/subService/slice';
import { selectServiceListAll, serviceActions } from 'src/features/superAdmin/service/slice';

// component
import TableListCombine from './tableListCombine/TableListCombine';

// scss
import styles from './styles.module.scss';

// Utils
import { convertArrToObjectOptions } from 'src/utils/help';

const CreateCombineService = () => {
  const cx = classNames.bind(styles);
  const dispatch = useDispatch();

  /**
   * Set state
   * active<Array>: set data to show service combine and not combine
   */
  const [isSearch, setIsSearch] = useState([]);
  const [active, setActive] = useState([]);

  // Selector
  const filter = useSelector(selectSubServiceFilter);
  const listServiceNotCombine = useSelector(selectListSubServiceNotCombine);
  const listService = useSelector(selectServiceListAll);
  const optionsServices = convertArrToObjectOptions(listService);

  const handleChangeFilterByService = (index) => {
    dispatch(subServiceActions.filter({ ...filter, serviceGroupId: index }));
  };

  useEffect(() => {
    if (listService.length > 0) {
      dispatch(
        subServiceActions.getAllSubService({
          isCombine: false,
          serviceGroupId: listService[0]._id,
        })
      );
    }
  }, [listService]);

  useEffect(() => {
    dispatch(serviceActions.getAllServiceWithoutParams());
  }, []);

  useEffect(() => {
    dispatch(
      subServiceActions.getAllSubService({
        isCombine: false,
        ...filter,
      })
    );
  }, [filter]);

  return (
    <div className={cx('crWrapper')}>
      <div className={cx('crTitle')}>Create Combines</div>
      <div className={cx('crContentCombine')}>
        <div className={cx('lCardDesktop')}>
          <TableListCombine
            listServiceNotCombine={listServiceNotCombine}
            optionsServices={optionsServices}
            handleChangeFilterByService={handleChangeFilterByService}
            isSearch={isSearch}
            setIsSearch={setIsSearch}
            active={active}
            setActive={setActive}
            serviceActions={serviceActions}
            filter={filter}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateCombineService;
