import { call, put, takeLatest } from 'redux-saga/effects';
import combineServiceApi from 'src/apis/superAdmin/combineServiceApi';
import { contentDialog } from 'src/utils/contentDialog';
import { history } from 'src/utils/history';
import { exactRouter } from 'src/utils/routers';
import { serviceActions } from '../service/slice';
import { combineServiceActions } from './slice';

function* handleGetAllCombineService(action) {
  try {
    const res = yield call(combineServiceApi.getAllCombineService, action.payload);
    const dataRes = res.data.data;

    yield put(combineServiceActions.getAllCombineServiceSuccess(dataRes));
  } catch (err) {
    yield put(combineServiceActions.getAllCombineServiceFail(err.message));
  }
}

function* handleCreateCombineService(action) {
  try {
    const res = yield call(combineServiceApi.createCombineService, action.payload.body);
    const data = res.data;

    yield put(combineServiceActions.createCombineServiceSuccess(data));
    yield put(serviceActions.resetListServiceNotCombine());

    history.push(exactRouter.superAdmin.listCombineService);
  } catch (err) {
    yield put(combineServiceActions.createCombineServiceFail(err.responses.data.message));
  }
}

function* handleUpdateCombineService(action) {
  try {
    // Call api update
    const res = yield call(combineServiceApi.updateCombineService, action.payload);
    const data = res.data;

    yield put(combineServiceActions.updateCombineServiceSuccess(data.message));
  } catch (err) {
    yield put(combineServiceActions.updateCombineServiceFail(err.responses.data.message));
  }
}

function* handleDeleteCombineService(action) {
  try {
    const res = yield call(combineServiceApi.deleteCombineService, action.payload);
    const data = res.data;

    yield put(combineServiceActions.deleteCombineServiceSuccess(contentDialog.DELETE.SUCCESS));
  } catch (error) {
    yield put(combineServiceActions.deleteCombineServiceFailed(error.responses.data.message));
  }
}

export default function* combineServiceSaga() {
  yield takeLatest(combineServiceActions.getAllCombineService.type, handleGetAllCombineService);
  yield takeLatest(combineServiceActions.createCombineService.type, handleCreateCombineService);
  yield takeLatest(combineServiceActions.updateCombineService.type, handleUpdateCombineService);
  yield takeLatest(combineServiceActions.deleteCombineService.type, handleDeleteCombineService);
}
