// component
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InputField from 'src/components/inputField';
import {
  houseActions,
  selectHouseholdLoading,
  selectHouseholdMessage,
  selectHouseholdShowMessage,
  selectHouseholdTypeAction,
} from 'src/features/superAdmin/household/slice';
import { digLogAction } from 'src/features/digLog/slice';
import TextareaField from 'src/components/textareaField';
import ListService from '../../service/list/List';
// Utils
import { CreateHouseHoldStep, EMAIL_REGEX, PHONE_NUMBER_CANADA } from 'src/utils/constants';
import { formatPhoneNumber, handleErrorMessages } from 'src/utils/help';
import { routerPaths } from 'src/utils/routers';
import { contentDialog } from 'src/utils/contentDialog';
// scss
import styles from './styles.modules.scss';

const CreateHouseHold = () => {
  // Util
  const navigate = useNavigate();

  const loading = useSelector(selectHouseholdLoading);
  const isShowMessage = useSelector(selectHouseholdShowMessage);
  const message = useSelector(selectHouseholdMessage);
  const typeAction = useSelector(selectHouseholdTypeAction);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const dispatch = useDispatch();
  const cx = classNames.bind(styles);

  const [stepHouseHold, setStepHouseHold] = useState(CreateHouseHoldStep.CREATE_HOUSEHOLD);
  const [householdData, setHouseholdData] = useState();
  const [listService, setListService] = useState([]);
  const [isHouseholdWithService, setIsHouseholdWithService] = useState(false);

  useEffect(() => {
    if (isShowMessage) {
      dispatch(
        digLogAction.getTitle({
          title: isHouseholdWithService
            ? contentDialog.CREATE_NEW_SERVICE.SUCCESS
            : contentDialog.CREATE_HOUSEHOLD.SUCCESS,
          function: () => {
            dispatch(houseActions.resetStatus());
            navigate(`/${routerPaths.SUPER_ADMIN}/${routerPaths.HOUSEHOLDS}/${routerPaths.LIST_HOUSE}`);
          },
        })
      );
      return;
    }

    if (isShowMessage === false) {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            dispatch(houseActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, isShowMessage, message, typeAction]);

  const handleCancel = (services) => {
    setListService(services);
    setStepHouseHold(CreateHouseHoldStep.CREATE_HOUSEHOLD);
  };

  const createSubmit = (data) => {
    setIsHouseholdWithService(false);
    setHouseholdData(convertFormData(data));

    dispatch(houseActions.createHousehold(convertFormData(data)));
  };

  const handleAddServices = (data) => {
    setHouseholdData(convertFormData(data));
    setStepHouseHold(CreateHouseHoldStep.LIST_SERVICE);
  };

  const convertFormData = (formData) => {
    return {
      name: {
        firstName: formData.firstName,
        lastName: formData.lastName,
      },
      address: formData.address,
      phoneNumber: {
        phone: formData.phoneNumber,
      },
      email: formData.email,
      houseInfo: formData.houseInfo,
    };
  };

  return (
    <>
      {stepHouseHold === CreateHouseHoldStep.CREATE_HOUSEHOLD && (
        <div className={`${cx('crHouseContainer')}`}>
          <div className={cx('crContent')}>
            <div className={cx('crFormWrap')}>
              <span className={cx('crTitle')}>Create New Household</span>
              <form className={cx('crForm')}>
                <div className={cx('crFormContent')}>
                  <InputField
                    label={'First Name*'}
                    classNameWrap={cx('crFormInput')}
                    classNameLabel={cx('crTitleInput')}
                    classNameInput={cx('crValueInput crFirstName')}
                    type={'text'}
                    register={register}
                    name={'firstName'}
                    validation={{
                      required: 'First name is required',
                    }}
                    onChange={(e) => setValue('firstName', e.target.value)}
                    error={errors['firstName']}
                  />

                  <InputField
                    label={'Last Name*'}
                    classNameWrap={cx('crFormInput')}
                    classNameLabel={cx('crTitleInput')}
                    classNameInput={cx('crValueInput crLastName')}
                    type={'text'}
                    register={register}
                    name={'lastName'}
                    validation={{
                      required: 'Last name is required',
                    }}
                    onChange={(e) => setValue('lastName', e.target.value)}
                    error={errors['lastName']}
                  />

                  <InputField
                    label={'Address*'}
                    classNameWrap={cx('crFormInput')}
                    classNameLabel={cx('crTitleInput')}
                    classNameInput={cx('crValueInput')}
                    type={'text'}
                    register={register}
                    name={'address'}
                    validation={{
                      required: 'Address is required',
                    }}
                    onChange={(e) => setValue('address', e.target.value)}
                    error={errors['address']}
                  />

                  <InputField
                    label={'Phone Number*'}
                    classNameWrap={cx('crFormInput')}
                    classNameLabel={'crTitleInput'}
                    register={register}
                    name={'phoneNumber'}
                    type={'text'}
                    max={12}
                    classNameInput={cx('crValueInput')}
                    placeholder={'000-000-0000'}
                    onChange={(e) => {
                      setValue('phoneNumber', `${formatPhoneNumber(e.target.value)}`);
                    }}
                    validation={{
                      required: 'Phone number is required',
                      pattern: {
                        value: PHONE_NUMBER_CANADA,
                        message: 'Phone Number format is not valid',
                      },
                    }}
                    error={errors['phoneNumber']}
                  />

                  <InputField
                    label={'Email*'}
                    classNameWrap={cx('crFormInput')}
                    classNameLabel={cx('crTitleInput')}
                    classNameInput={cx('crValueInput')}
                    type={'email'}
                    register={register}
                    name={'email'}
                    validation={{
                      required: 'Email is required',
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'Email format is not valid',
                      },
                    }}
                    onChange={(e) => setValue('email', e.target.value)}
                    error={errors['email']}
                  />

                  <TextareaField
                    label={'House Info'}
                    classNameWrap={cx('crFormTextarea')}
                    classNameLabel={cx('crTitleInput')}
                    classNameTextarea={`${cx('crValueTextarea')}`}
                    type={'text'}
                    register={register}
                    name={'houseInfo'}
                    onChange={(e) => setValue('houseInfo', e.target.value)}
                  />
                </div>

                <div className={cx('hhCrButton')}>
                  <button
                    type='submit'
                    disabled={loading || isShowMessage}
                    className={cx('formButton', 'saveButton')}
                    onClick={handleSubmit(createSubmit)}
                  >
                    Save
                  </button>

                  <button
                    type='button'
                    disabled={loading || isShowMessage}
                    className={cx('formButton')}
                    onClick={handleSubmit(handleAddServices)}
                  >
                    Add New Service
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {stepHouseHold === CreateHouseHoldStep.LIST_SERVICE && (
        <ListService
          initHouseholdData={householdData}
          initServices={listService}
          onSave={() => setIsHouseholdWithService(true)}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default CreateHouseHold;
