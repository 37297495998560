import classNames from 'classnames/bind';
import { useState, useEffect } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// import component common
import TableItem from './table/tableItem/tableItem';

//utils
import { generateRandomString, handleErrorMessages } from 'src/utils/help';
import {
  IMAGE_REQUIRED,
  MAX_SERVICE_PER_HOUSEHOLD,
  SUBSERVICE_NAME_REQUIRED,
  WRONG_PRICE_FORMAT,
} from 'src/utils/constants';
import { routerPaths } from 'src/utils/routers';
import { contentDialog } from 'src/utils/contentDialog';
import { CheckRoleUser } from 'src/utils/enum';

//others
import { selectAuthRole } from 'src/features/auth/authSlice';
import { digLogAction } from 'src/features/digLog/slice';
import {
  selectServiceData,
  selectServiceLoading,
  selectServiceMessage,
  selectServiceShowMessage,
  selectServiceTypeAction,
  serviceActions,
} from 'src/features/superAdmin/service/slice';
import { selectSubServiceList, subServiceActions } from 'src/features/superAdmin/subService/slice';

//scss
import styles from './styles.module.scss';
const cx = classNames.bind(styles);

const ListSubService = (props) => {
  const { initServiceData, initListSubService, onSave, onCancel } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { houseHoldId, serviceId } = useParams();

  /**
   * UseSelector Block
   */
  const serviceDataSelector = useSelector(selectServiceData);
  const subServiceListSelector = useSelector(selectSubServiceList);
  const loading = useSelector(selectServiceLoading);
  const isShowMessage = useSelector(selectServiceShowMessage);
  const message = useSelector(selectServiceMessage);
  const typeAction = useSelector(selectServiceTypeAction);
  const role = useSelector(selectAuthRole);

  const [listConvertedSubService, setListConvertedSubService] = useState([]);
  const [listSubService, setListSubService] = useState(initListSubService ?? []);
  const [listDeletedSubService, setListDeletedSubService] = useState([]);
  const [serviceData, setServiceData] = useState(initServiceData);
  const isEmployee = role === CheckRoleUser.EMPLOYEE;

  /**
   * UseEffect Block
   */

  useEffect(() => {
    return () => {
      if (serviceId) {
        dispatch(serviceActions.resetState());
      }
      dispatch(subServiceActions.resetState());
    };
  }, []);

  useEffect(() => {
    if (isShowMessage === true && typeAction === 'UPDATE_SUB_SERVICES') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.UPDATE_SUBSERVICE.SUCCESS,
          function: () => {
            dispatch(serviceActions.resetStatus());
            navigate(
              `/${routerPaths.SUPER_ADMIN}/${routerPaths.HOUSEHOLDS}/${houseHoldId}/?tab=${routerPaths.SERVICES}`
            );
          },
        })
      );
    } else if (isShowMessage === false) {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            dispatch(serviceActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, isShowMessage, message, typeAction]);

  useEffect(() => {
    if (serviceDataSelector && serviceId) {
      setServiceData(serviceDataSelector);
    }
  }, [serviceDataSelector]);

  useEffect(() => {
    if (subServiceListSelector && serviceId) {
      setListSubService(subServiceListSelector);
    }
  }, [subServiceListSelector]);

  useEffect(() => {
    if (serviceId) {
      dispatch(serviceActions.getDetailService(serviceId));
      dispatch(subServiceActions.getAllSubService({ serviceGroupId: serviceId, limit: MAX_SERVICE_PER_HOUSEHOLD }));
    }
  }, [serviceId]);

  useEffect(() => {
    const updatedList = listSubService.map((subService) => {
      if (!subService._id) {
        return { ...subService, tempKey: generateRandomString(10) };
      }
      return subService;
    });
    while (updatedList.length < MAX_SERVICE_PER_HOUSEHOLD) {
      updatedList.push({ tempKey: generateRandomString(10) });
    }

    setListConvertedSubService(updatedList);
  }, [listSubService]);

  const hasSubServiceValue = (subService) =>
    subService.logo ||
    subService.name?.toString()?.trim() ||
    subService.isActive ||
    subService?.price?.toString()?.trim() ||
    subService.notes?.toString()?.trim();

  const onChangeValue = (key, field, value) => {
    let updatedList = listConvertedSubService.map((subService, index) => {
      let newSubService = subService;

      if ((subService._id || subService.tempKey) === key) {
        newSubService = { ...newSubService, [field]: value };

        if (newSubService.error) {
          let newError = newSubService.error;
          if (value?.toString()?.trim()) {
            const { [field]: removedErrorField, ...restError } = newSubService.error;
            newError = restError;
          }

          newSubService.error = hasSubServiceValue(newSubService) ? newError : {};
        }
      }

      return newSubService;
    });

    setListConvertedSubService([...updatedList]);
  };

  const handleCancel = () => {
    if (serviceId) {
      navigate(-1);
      return;
    }

    onCancel && onCancel();
  };

  const onDeleteSubService = (key) => {
    const deleteSubService = listConvertedSubService.find(
      (subService) => (subService._id || subService.tempKey) === key
    );
    if (deleteSubService?._id) {
      setListDeletedSubService((prevSubServices) => [...prevSubServices, deleteSubService?._id]);
    }

    let updatedList = listConvertedSubService.filter((subService) => (subService._id || subService.tempKey) !== key);

    while (updatedList.length < MAX_SERVICE_PER_HOUSEHOLD) {
      updatedList.push({ tempKey: generateRandomString(10) });
    }

    setListConvertedSubService([...updatedList]);
  };

  const handleSaveSubService = () => {
    /// Validate
    const updatedList = listConvertedSubService.map((item) => {
      let error = {};

      if (hasSubServiceValue(item)) {
        if (!item.logo) error.logo = IMAGE_REQUIRED;
        if (!item.name?.toString()?.trim()) error.name = SUBSERVICE_NAME_REQUIRED;
        if (item?.price?.toString()?.trim() && isNaN(item?.price?.toString()?.trim())) error.price = WRONG_PRICE_FORMAT;
      }

      return { ...item, error };
    });

    setListConvertedSubService(updatedList);

    const hasErrors = updatedList.some((item) => item.error && Object.keys(item.error).length > 0);
    if (hasErrors) {
      dispatch(digLogAction.getTitle({ title: 'Invalid Sub-Service Exists' }));
      return;
    }

    /// Add empty sub-service to delete array
    const listEmptySubServiceIds = [];
    updatedList.forEach((item) => {
      if (item._id && !item.logo && !item.name?.toString()?.trim()) {
        listEmptySubServiceIds.push(item._id);
      }
    });

    /// New Sub-Services
    const listNewSubService = updatedList
      .filter((value) => value.tempKey && value.logo && value.name?.toString()?.trim())
      .map(({ tempKey, error, ...rest }) => rest);

    /// Update Sub-Services
    const listUpdateSubService = listSubService.reduce((acc, subService) => {
      const matchingItem = updatedList
        .filter((value) => value._id && value.logo && value.name?.toString()?.trim())
        .find((item) => item._id === subService._id);

      if (matchingItem) {
        const { error, ...itemWithoutErrorMessage } = matchingItem;

        if ((serviceId && JSON.stringify(subService) !== JSON.stringify(itemWithoutErrorMessage)) || !serviceId) {
          acc.push(itemWithoutErrorMessage);
        }
      }

      return acc;
    }, []);

    const payloadData = {
      subServices: [...listNewSubService, ...listUpdateSubService].map(
        ({ serviceGroup, price, ...restSubService }) => ({
          ...restSubService,
          price: price?.toString()?.trim() && !isNaN(price?.toString()?.trim()) ? price : null,
        })
      ),
      deleteSubServiceIds: [...listDeletedSubService, ...listEmptySubServiceIds],
    };

    /// Update Sub-Services in database
    if (serviceId) {
      payloadData._id = serviceId;
      dispatch(serviceActions.updateSubServices(payloadData));
      return;
    }

    /// Pass data to services screen (not save in database)
    onSave && onSave(payloadData);
  };

  return (
    <>
      <div className={cx('lContainerWrapper')}>
        <div className={cx('lService')}>
          <p className={cx('lServiceName')}>{serviceData?.name && `${serviceData?.name} Sub-Services`}</p>
        </div>

        <div className={cx('lBlock')}>
          <div className={cx('lItemDesktop')}>
            <div className={cx('lItemWrapper')}>
              {listConvertedSubService.map((value, i) => {
                const key = value._id || value.tempKey;
                return (
                  <TableItem
                    key={key}
                    {...value}
                    onChange={(field, value) => onChangeValue(key, field, value)}
                    onDelete={() => onDeleteSubService(key)}
                    disabled={isEmployee}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <div className={cx('containerButton')}>
          <button type='button' className={cx('actionButton', 'buttonCancel')} onClick={handleCancel}>
            Cancel
          </button>

          <button
            disabled={isEmployee}
            type='button'
            className={cx('actionButton', `${isEmployee && 'buttonDisable'}`)}
            onClick={handleSaveSubService}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default ListSubService;
