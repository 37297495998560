// Libs
import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select, { StylesConfig } from 'react-select';
// Icons
import iconClose from 'src/assets/icons/ic_close.svg';
// Components
import Overlay from 'src/components/common/overlay/Overlay';
import Dropdown from 'src/components/dropdown';
import InputField from 'src/components/inputField';
// Thunks
import {
  houseActions,
  selectHouseholdIsSuccess,
  selectHouseholdLoading,
  selectHouseholdOpenForm,
  selectHouseholdResetForm,
  selectHouseholdShowMessage,
} from 'src/features/superAdmin/household/slice';
// Utils
import { EMAIL_REGEX, PHONE_NUMBER_CANADA, POSTAL_CODE_CANADA, TIME_OPTIONS } from 'src/utils/constants';
import { houseHoldDropdown } from 'src/utils/enum';
import { householdProvice } from 'src/utils/enum';
import {
  convertTimeCalendarRequestToLocal,
  convertTimeRangeCalendar,
  formatPhoneNumber,
  formatPostalCode,
  getCurrentTimezone,
} from 'src/utils/help';
// scss
import styles from './styles.module.scss';
import {
  requestActions,
  selectBookedCalendarList,
  selectIsSuccessRequest,
} from 'src/features/superAdmin/requests/slice';

const EditOrders = (props) => {
  const { showEdit, setShowEdit, listEdit, setListEdit } = props;
  // const showAdd = useSelector(selectHouseholdOpenForm);
  const isUpdateSuccess = useSelector(selectIsSuccessRequest);
  const loading = useSelector(selectHouseholdLoading);
  const isShowMessage = useSelector(selectHouseholdShowMessage);
  const bookedCalendarList = useSelector(selectBookedCalendarList);

  // const resetForm = useSelector(selectHouseholdResetForm);

  const [options, setOptions] = useState();
  const [province, setProvince] = useState(Object.keys(householdProvice.values)[0]);
  const [dropOne, setDropOne] = useState(false);
  const [dropTwo, setDropTwo] = useState(false);
  const [showDropdown, setShowDropdown] = useState([]);
  const [up, setUp] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();
  const trackingValueDate = watch('date');

  const dispatch = useDispatch();
  const cx = classNames.bind(styles);

  useEffect(() => {
    const arr = [...Array(2)].map((value) => false);
    setShowDropdown(arr);
  }, []);

  const handleDropdown = (index) => {
    const arr = [...Array(2)].map((value) => false);
    arr[index] = true;
    setShowDropdown(arr);
  };

  const handleUpdateOrder = (data) => {
    const formData = {
      body: {
        date: data?.date,
        calendar: convertTimeRangeCalendar(data.date, options),
        infoCustomer: {
          city: data?.city,
          streetAddress: data?.streetAddress,
          province: province ? province : listEdit?.infoCustomer?.provice,
          postalCode: formatPostalCode(data.postalCode),
        },
      },
      _id: listEdit !== undefined ? listEdit._id : null,
    };

    if (!formData) {
      return;
    }

    dispatch(requestActions.updateRequest(formData));
  };

  // Get list time
  useEffect(() => {
    if (!trackingValueDate && !listEdit?.date) {
      return;
    }

    const date = trackingValueDate || listEdit?.date;
    const timezone = getCurrentTimezone();
    dispatch(requestActions.getBookedCalendar({ date, timezone }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listEdit?.date, trackingValueDate]);

  useEffect(() => {
    if (!listEdit?.calendar || bookedCalendarList.length <= 0) {
      return;
    }
    const dateTime = convertTimeCalendarRequestToLocal(listEdit?.calendar);

    setOptions(dateTime);
  }, [bookedCalendarList?.length, listEdit?.calendar]);

  useEffect(() => {
    if (!listEdit) {
      return;
    }

    // Set data for inputs
    const formUpdate = listEdit && {
      date: listEdit.date,
      city: listEdit.infoCustomer.city,
      streetAddress: listEdit.infoCustomer.streetAddress,
      province: listEdit.infoCustomer.provice,
      postalCode: listEdit.infoCustomer.postalCode,
    };

    reset(formUpdate);
    setProvince(listEdit.infoCustomer?.provice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listEdit]);

  /** reset form when update success */

  // useEffect(() => {
  //   if (showAdd === true) {
  //     reset({});
  //     setListEdit();
  //     setOptions(Object.keys(houseHoldDropdown.values)[0]);
  //     setProvince(Object.keys(householdProvice.values)[0]);
  //     setShowEdit(false);
  //     dispatch(houseActions.cloneForm());
  //     setUp(!up);
  //   }
  // }, [showAdd]);

  /** when create success -> clear form, false -> keep form */
  useEffect(() => {
    if (!isUpdateSuccess) {
      return;
    }

    handleCloseSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSuccess]);

  // useEffect(() => {
  //   if (resetForm === true) {
  //     setShowEdit(false);
  //     reset();
  //     setListEdit();
  //     setOptions();
  //     setProvince();
  //     dispatch(houseActions.resetFormClose());
  //   }
  // }, [resetForm]);

  const handleCloseSidebar = () => {
    reset({});
    setListEdit();
    setOptions();
    setProvince();
    setShowEdit(false);
    setUp(false);
  };

  // Custom style multi service name
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '4px',
      borderColor: `var(--border-color)`,
      boxShadow: null,
      minHeight: '46px',
      cursor: 'pointer',
      '&:hover': {
        borderColor: `var(--border-color)`,
      },
      '&:active': {
        borderColor: `var(--border-color)`,
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : null,
      color: state.isSelected ? 'white' : null,
      padding: '14px 12px',
      '&:hover': {
        backgroundColor: 'var(--first-color-light)',
        color: 'var(--first-color)',
        cursor: 'pointer',
      },
    }),
    menu: (provided) => ({
      ...provided,
      position: 'relative',
      boxShadow: null,
      border: '1px solid var(--border-color)',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#a9a9a9',
      fontFamily: 'var(--body-font)',
      fontSize: 'var(--normal-font-size)',
      fontWeight: 'var(--font-medium)',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'var(--text-color)',
      '&:hover': {
        color: 'var(--text-color)',
      },
    }),
  };

  return (
    <>
      <div
        className={`${cx('crHouseContainer')} ${showEdit ? cx('crHouseContainerShow') : cx('')}`}
        onClick={() => {
          if (dropOne) {
            setDropOne(false);
          } else if (dropTwo) {
            setDropTwo(false);
          }
        }}
      >
        <div className={cx('crContent')}>
          <div className={cx('crClose')}>
            <div className={cx('crButton')} onClick={handleCloseSidebar}>
              <img src={iconClose} alt='iconClose' />
            </div>
          </div>

          <span className={cx('crTitle')}>Edit Orders</span>

          <div className={cx('crFormWrap')}>
            <form className={cx('crForm')} onSubmit={handleSubmit(handleUpdateOrder)}>
              <div className={cx('crFormScroll')}>
                <InputField
                  label={'Date'}
                  classNameWrap={cx('crFormInput')}
                  classNameLabel={cx('crTitleInput')}
                  classNameInput={cx('crValueInput crFirstName')}
                  type={'date'}
                  register={register}
                  name={'date'}
                  validation={{
                    required: 'Date is required',
                  }}
                  onChange={(e) => setValue('date', e.target.value)}
                  error={errors['date']}
                />

                <div className={cx('crFormInput')}>
                  <label htmlFor='input' className={cx('crTitleInput')}>
                    Time
                  </label>
                  <Dropdown
                    register={register}
                    name={'time'}
                    selected={bookedCalendarList}
                    options={options || 'Loading...'}
                    setOptions={setOptions}
                    timePicker
                  />
                </div>

                <InputField
                  label={'Street*'}
                  classNameWrap={cx('crFormInput')}
                  classNameLabel={cx('crTitleInput')}
                  classNameInput={cx('crValueInput crStreet')}
                  type={'text'}
                  register={register}
                  name={'streetAddress'}
                  validation={{
                    required: 'Street is required',
                  }}
                  onChange={(e) => setValue('streetAddress', e.target.value)}
                  error={errors['streetAddress']}
                />

                <InputField
                  label={'City*'}
                  classNameWrap={cx('crFormInput')}
                  classNameLabel={cx('crTitleInput')}
                  classNameInput={cx('crValueInput crCity')}
                  type={'text'}
                  register={register}
                  name={'city'}
                  validation={{
                    required: 'City is required',
                  }}
                  onChange={(e) => setValue('city', e.target.value)}
                  error={errors['city']}
                />

                <div className={cx('crFormInput')}>
                  <label htmlFor='input' className={cx('crTitleInput')}>
                    Province
                  </label>
                  <Dropdown
                    register={register}
                    name={'province'}
                    className={cx('crProvince')}
                    selected={householdProvice.values}
                    options={province}
                    up={up}
                    setOptions={setProvince}
                    valueUpdateProvince={
                      householdProvice.values[listEdit === undefined ? null : listEdit.infoCustomer.provice]
                    }
                    show={showDropdown[1]}
                    index={1}
                    setshow={handleDropdown}
                  />
                </div>

                <InputField
                  label={'Postal Code*'}
                  classNameWrap={cx('crFormInput')}
                  classNameLabel={cx('crTitleInput')}
                  classNameInput={cx('crValueInput crValuePostalCode')}
                  type={'text'}
                  register={register}
                  validation={{
                    required: 'Postal code is required',
                    pattern: {
                      value: POSTAL_CODE_CANADA,
                      message: 'Postal code format is not valid',
                    },
                  }}
                  name={'postalCode'}
                  max={7}
                  placeholder={'X0X 0X0'}
                  onChange={(e) => {
                    setValue('postalCode', `${formatPostalCode(e.target.value)}`);
                  }}
                  error={errors['postalCode']}
                />

                {listEdit && (
                  <div className={cx('crFormInput')}>
                    <label htmlFor='serviceName' className={cx('crTitleInput')}>
                      Service Name
                    </label>

                    <Select
                      name='serviceName'
                      closeMenuOnSelect={false}
                      isMulti
                      options={[]}
                      defaultValue={listEdit.services.map((subService) => ({
                        value: subService._id,
                        label: subService.name,
                      }))}
                      isDisabled
                      styles={customStyles}
                      menuIsOpen={isOpen}
                      onMenuOpen={() => setIsOpen(true)}
                      onMenuClose={() => setIsOpen(false)}
                      isSearchable={false}
                    />
                  </div>
                )}
              </div>

              <div className={cx('hhCrButton')}>
                <button type='submit' disabled={loading || isShowMessage} className={cx('btnCreate')}>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showEdit && <Overlay showEdit={showEdit} setShowEdit={setShowEdit} handleCloseSidebar={handleCloseSidebar} />}
    </>
  );
};

export default EditOrders;
