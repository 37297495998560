import { call, put, takeLatest } from 'redux-saga/effects';
import requestApi from 'src/apis/superAdmin/requestApi';
import { digLogAction } from 'src/features/digLog/slice';
import { contentDialog } from 'src/utils/contentDialog';

// Slice
import { requestActions } from './slice';

function* handleGetAllRequest(action) {
  try {
    const data = yield call(requestApi.getAllRequest, action.payload);
    yield put(requestActions.getAllRequestSuccess(data.data));
  } catch (error) {
    yield put(requestActions.getAllRequestFail(error));
  }
}

function* handleGetUpdateRequest(action) {
  try {
    const { data } = yield call(requestApi.updateRequest, action.payload);
    yield put(requestActions.updateRequestSuccess(data));

    // window.location.reload();

    // const dataRes = yield call(requestApi.getAllRequest);
  } catch (error) {
    yield put(requestActions.updateRequestFail(error?.response.data.message));
    yield put(digLogAction.getTitle({ title: contentDialog.UPDATE.FAILED }));
  }
}

function* handleGetBookedCalendar(action) {
  try {
    const response = yield call(requestApi.getBookedCalendar, action.payload);

    const bookedCalendarList = response.data.data.calendarAvailable;
    const bookedCalendarListLowerCase = bookedCalendarList.map((time) => time.toLocaleLowerCase());
    yield put(requestActions.getBookedCalendarSuccess(bookedCalendarListLowerCase));
  } catch (error) {
    // yield put(digLogAction.getTitle({ title: contentDialog.GET.FAILED }));
    console.log(`handleGetBookedCalendar -> error: ${JSON.stringify(error, null, 3)}`);
  }
}

function* handleDeleteRequest(action) {
  try {
    const { data } = yield call(requestApi.deleteRequest, action.payload);
    yield put(requestActions.deleteRequestSuccess());
  } catch (error) {
    yield put(requestActions.deleteRequestFail(error.response.data.message));
  }
}

function* handleGetDetailRequest(action) {
  try {
    const { data } = yield call(requestApi.getDetailRequest, action.payload);
    yield put(requestActions.getDetailRequestSuccess(data.data));
  } catch (error) {
    yield put(requestActions.getDetailRequestFail(error.response.data.message));
  }
}

export default function* requestSaga() {
  yield takeLatest(requestActions.getAllRequest.type, handleGetAllRequest);
  yield takeLatest(requestActions.updateRequest.type, handleGetUpdateRequest);
  yield takeLatest(requestActions.getBookedCalendar.type, handleGetBookedCalendar);
  yield takeLatest(requestActions.deleteRequest.type, handleDeleteRequest);
  yield takeLatest(requestActions.getDetailRequest.type, handleGetDetailRequest);
}
