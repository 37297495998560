import { call, put, takeLatest } from 'redux-saga/effects';
import householdApi from 'src/apis/superAdmin/householdApi';
import { digLogAction } from 'src/features/digLog/slice';
import { handleErrorMessages } from 'src/utils/help';
import { houseActions } from './slice';

function* handleGetHousehold(action) {
  try {
    console.log(`[Household<Saga>][handleGetHousehold] payload -> ${JSON.stringify(action.payload)}`);

    const { data } = yield call(householdApi.getAllHousehold, action.payload);

    yield put(houseActions.getAllHouseholdSuccess(data.data));
  } catch (err) {
    yield put(houseActions.getAllHouseholdFail(err.message));
    // Show digLog
    yield put(digLogAction.getTitle({ title: handleErrorMessages(err.response.data.message) }));
  }
}

function* handleCreateHousehold(action) {
  try {
    yield call(householdApi.createHouseHold, action.payload);
    // yield put(houseActions.getAllHousehold());
    yield put(houseActions.createHouseholdSuccess(action.payload));
  } catch (error) {
    yield put(houseActions.createHouseholdFail(error.response.data.message));
  }
}

function* handleGetDetailHousehold(action) {
  try {
    const { data } = yield call(householdApi.getDetailHousehold, action.payload);
    yield put(houseActions.getDetailHouseholdSuccess(data.data));
  } catch (error) {
    yield put(houseActions.getDetailHouseholdFail(error.response.data.message));
  }
}

function* handleDeleteHousehold(action) {
  try {
    const { data } = yield call(householdApi.deleteHousehold, action.payload);
    yield put(houseActions.deleteHouseholdSuccess());
  } catch (error) {
    yield put(houseActions.deleteHouseholdFail(error.response.data.message));
  }
}

function* handleUpdateHousehold(action) {
  try {
    const { data } = yield call(householdApi.updateHousehold, action.payload);
    // yield put(houseActions.getAllHousehold(data));
    yield put(houseActions.updateHouseholdSuccess(data));
  } catch (error) {
    yield put(houseActions.updateHouseholdFail(error.response.data.message));
  }
}

function* handleUpdateHouseholdServices(action) {
  try {
    const { data } = yield call(householdApi.updateHouseholdServices, action.payload);
    yield put(houseActions.updateHouseHoldServicesSuccess(data));
  } catch (error) {
    yield put(houseActions.updateHouseHoldServicesFail(error.response.data.message));
  }
}

export default function* householdSaga() {
  yield takeLatest(houseActions.getAllHousehold.type, handleGetHousehold);
  yield takeLatest(houseActions.createHousehold.type, handleCreateHousehold);
  yield takeLatest(houseActions.deleteHousehold.type, handleDeleteHousehold);
  yield takeLatest(houseActions.updateHousehold.type, handleUpdateHousehold);
  yield takeLatest(houseActions.getDetailHousehold.type, handleGetDetailHousehold);
  yield takeLatest(houseActions.updateHouseHoldServices.type, handleUpdateHouseholdServices);
}
