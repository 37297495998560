import { notInitialized } from 'react-redux/es/utils/useSyncExternalStore';
import { exactRouter } from './routers';

// Icons
import iconHouseholds from 'src/assets/icons/ic_house_holds.svg';
import iconHouseholdsActive from 'src/assets/icons/ic_house_holds_active.svg';
import iconOrders from 'src/assets/icons/ic_orders.svg';
import iconOrdersActive from 'src/assets/icons/ic_orders_active.svg';

export const menu = [
  {
    NAME: 'Households',
    PATH: exactRouter.employee.listHouseholds,
    ICON: iconHouseholds,
    ICON_ACTIVE: iconHouseholdsActive,
  },
  {
    NAME: 'Orders',
    PATH: exactRouter.employee.listOrders,
    ICON: iconOrders,
    ICON_ACTIVE: iconOrdersActive,
  },
];

export const serviceFilter = {
  values: {
    All: 'All Status',
    ACTIVE: 'Active',
    ISACTIVE: 'Inactive',
  },
  keys: {
    All: null,
    ACTIVE: true,
    ISACTIVE: false,
  },
};

export const subServiceFilter = {
  values: {
    All: 'All Status',
    ACTIVE: 'Active',
    ISACTIVE: 'Inactive',
  },
  keys: {
    All: null,
    ACTIVE: true,
    ISACTIVE: false,
  },
};

export const houseHoldFilter = {
  values: {
    All: 'All Sizes',
    MASSIVE: 'Massive',
    LARGE: 'Large',
    MEDIUM: ' Medium',
    SMALL: 'Small',
  },
  keys: {
    ALL: null,
    MASSIVE: 'MASSIVE',
    LARGE: 'LARGE',
    MEDIUM: 'MEDIUM',
    SMALL: 'SMALL',
  },
};

export const serviceDropdown = {
  values: {
    ACTIVE: 'Active ',
    ISACTIVE: 'Inactive',
  },
  keys: {
    ACTIVE: true,
    ISACTIVE: false,
  },
  edits: {
    true: 'Active',
    false: 'Inactive',
  },
};

export const houseHoldDropdown = {
  values: {
    SMALL: 'Small',
    MEDIUM: 'Medium',
    LARGE: 'Large',
    MASSIVE: 'Massive',
  },
};

export const genderUser = {
  values: {
    MALE: 'Male',
    FEMALE: 'Female',
  },
};

export const householdProvice = {
  values: {
    AB: 'AB',
    BC: 'BC',
    MB: 'MB',
    NS: 'NS',
    NB: 'NB',
    ON: 'ON',
    PE: 'PE',
    QC: 'QC',
    SK: 'SK',
    YT: 'YT',
  },
};

export const roleUser = {
  values: {
    ADMIN: 'Admin',
    EMPLOYEE: 'Employee',
  },
};

export const statusUser = {
  values: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
  },
};

export const StorageConstants = {
  ACCESS_TOKEN: 'access_token',
  CURRENT_USER: 'current_user',
  USER_PROFILE: 'user_profile',
};

export const FilterTypeRequest = {
  values: {
    ALL: 'All Types',
    INSIDE: 'Inside',
    OUTSIDE: 'Outside',
    BOTH: 'Both',
    OTHER: 'N/A',
  },
  keys: {
    ALL: null,
    INSIDE: 'INSIDE',
    OUTSIDE: 'OUTSIDE',
    BOTH: 'BOTH',
    OTHER: 'OTHER',
  },
};

export const FilterStatusRequest = {
  values: {
    ALL: 'All status',
    COMPLETE: 'Complete',
    INCOMPLETE: 'Incomplete',
  },
  keys: {
    ALL: null,
    COMPLETE: 'COMPLETE',
    INCOMPLETE: 'INCOMPLETE',
  },
};

export const CheckRoleUser = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  EMPLOYEE: 'EMPLOYEE',
};

export const ActionType = {
  UPLOAD_FILE: 'UPLOAD_FILE',
};
