import axiosClient from '../axiosClient';

const imageApi = {
  postImage(file) {
    const url = `/files/upload`;
    return axiosClient.post(url, file);
  },
};

export default imageApi;
