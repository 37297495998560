import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  isLoading: null,
  isCombine: null,
  listService: [],
  listServiceAll: [],
  listServiceNotCombine: [],
  serviceData: null,
  total: null,
  paginationTotal: null,
  showAdd: false,
  typeAction: null,
  isShowMessage: null,
  isFetchDataAfterAction: false,
  filter: {
    page: 1,
    limit: 10,
    isActive: null,
    search: '',
  },
  message: '',
};

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    // Get All Service
    getAllService(state, actions) {
      state.loading = true;
      state.isCombine = actions.payload.isCombine;
    },
    getAllServiceSuccess(state, actions) {
      state.loading = false;
      if (state.isCombine === false) {
        state.listServiceNotCombine = actions.payload.servicesNotCombine;
        state.total = actions.payload.servicesNotCombine.length;
      } else {
        state.listService = actions.payload.serviceGroups;
        state.total = actions.payload.total;
        state.paginationTotal = actions.payload.pagination.total;
      }
    },
    getAllServiceFail(state, action) {
      state.loading = false;
    },

    // Get All Service Without Params
    getAllServiceWithoutParams(state, actions) {
      state.loading = true;
    },
    getAllServiceWithoutParamsSuccess(state, actions) {
      state.loading = false;
      if (state.isCombine === false) {
        state.listServiceNotCombine = actions.payload.servicesNotCombine;
        // state.total = actions.payload.servicesNotCombine.length;
      } else {
        state.listServiceAll = actions.payload.serviceGroups;
      }
    },
    getAllServiceWithoutParamsFail(state, action) {
      state.loading = false;
    },

    // Create Service
    createService(state, action) {
      state.loading = true;
      state.isFetchDataAfterAction = false;
    },
    createServiceSuccess(state, action) {
      state.loading = false;
      state.isFetchDataAfterAction = true;
      state.showAdd = true;
      state.isShowMessage = true;
      state.typeAction = 'CREATE';
    },
    createServiceFail(state, action) {
      state.loading = false;
      state.isFetchDataAfterAction = false;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },

    // Delete Service
    deleteService(state, action) {
      state.loading = true;
      state.isFetchDataAfterAction = false;
    },
    deleteServiceSuccess(state, action) {
      state.loading = false;
      state.isFetchDataAfterAction = true;
      state.isShowMessage = true;
      state.typeAction = 'DELETE';
    },
    deleteServiceFailed(state, action) {
      state.loading = false;
      state.isFetchDataAfterAction = false;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },

    // Update Service
    updateService(state, action) {
      state.loading = true;
      state.isFetchDataAfterAction = false;
    },
    updateServiceSuccess(state, action) {
      state.loading = false;
      state.isFetchDataAfterAction = true;
      state.showAdd = true;
      state.typeAction = 'UPDATE';
      state.isShowMessage = true;
    },
    updateServiceFail(state, action) {
      state.loading = false;
      state.isFetchDataAfterAction = false;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },

    // Update Sub-Services
    updateSubServices(state, action) {
      state.loading = true;
    },
    updateSubServicesSuccess(state, action) {
      state.loading = false;
      state.typeAction = 'UPDATE_SUB_SERVICES';
      state.isShowMessage = true;
    },
    updateSubServicesFail(state, action) {
      state.loading = false;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },

    // Get Detail Service
    getDetailService(state, action) {
      state.loading = true;
    },
    getDetailServiceSuccess(state, action) {
      state.loading = false;
      state.typeAction = 'DETAIL';
      state.serviceData = action.payload;
    },
    getDetailServiceFail(state, action) {
      state.loading = false;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },

    // Filter
    filter(state, action) {
      state.filter = action.payload;
    },
    showAdd(state, action) {
      state.showAdd = true;
    },
    cloneAdd(state, action) {
      state.showAdd = false;
    },

    // Reset data in store
    resetListServiceNotCombine(state) {
      state.listServiceNotCombine = initialState.listServiceNotCombine;
    },
    resetState: () => initialState,
    resetStatus(state) {
      state.message = '';
      state.isShowMessage = null;
      state.typeAction = '';
    },
  },
});

export const serviceActions = serviceSlice.actions;

export const selectServiceList = (state) => state.service.listService;
export const selectServiceListAll = (state) => state.service.listServiceAll;
export const selectListServiceNotCombine = (state) => state.service.listServiceNotCombine;
export const selectServiceTotal = (state) => state.service.total;
export const selectServiceFilter = (state) => state.service.filter;
export const selectServiceLoading = (state) => state.service.loading;
export const selectServiceShowAdd = (state) => state.service.showAdd;
export const selectServicePaginationTotal = (state) => state.service.paginationTotal;
export const selectServiceTypeAction = (state) => state.service.typeAction;
export const selectServiceMessage = (state) => state.service.message;
export const selectServiceShowMessage = (state) => state.service.isShowMessage;
export const selectServiceFetchDataAfterAction = (state) => state.service.isFetchDataAfterAction;
export const selectServiceData = (state) => state.service.serviceData;

const serviceReducer = serviceSlice.reducer;
export default serviceReducer;
