import axiosClient from '../axiosClient';
import { CONTENT_TYPE_FORM_DATA, fileUrl } from 'src/utils/constants';

const fileApi = {
  uploadFile(params) {
    const url = `${fileUrl.uploadFile}`;
    return axiosClient.post(url, params, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },
};

export default fileApi;
