import classNames from 'classnames/bind';
import React from 'react';
import { useSelector } from 'react-redux';
// component
import ImagePicker from 'src/components/common/imagePicker/ImagePicker';
// others
import { selectAuthRole } from 'src/features/auth/authSlice';
import { CheckRoleUser } from 'src/utils/enum';
//Scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const TableServiceItem = (props) => {
  const { _id, name, img, onDelete, onEditService } = props;
  const role = useSelector(selectAuthRole);
  const isEmployee = role === CheckRoleUser.EMPLOYEE;

  return (
    <>
      <div className={cx('itTableItemWrap')}>
        <div id='itItemService' className={cx('itTableItem')}>
          <div className={cx('itTbServiceName')}>
            <ImagePicker image={img} readOnly={true} />
            <div className={cx('nameService')}>{name}</div>
          </div>

          <div className={cx('itItemActions')}>
            {/* <div
              className={cx('itBtnGroupEdit', 'deleteButton')}
              onClick={() => {
                onDelete && onDelete();
              }}
            >
              Delete Service
            </div> */}

            <div
              className={cx('itBtnGroupEdit')}
              onClick={() => {
                onEditService && onEditService();
              }}
            >
              {isEmployee ? 'View Service' : 'Edit Service'}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableServiceItem;
