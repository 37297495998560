import { call, put, takeLatest } from 'redux-saga/effects';
import imageApi from 'src/apis/superAdmin/imageApi';
import userApi from 'src/apis/superAdmin/useManagementApi';
import { userManagementAction } from './slice';

function* handleGetAllUserManagement(action) {
  try {
    const response = yield call(userApi.getAllUser, action.payload);
    const data = response.data.data;

    yield put(userManagementAction.getAllUserManagementSuccess(data));
  } catch (err) {
    yield put(userManagementAction.getAllUserManagementFailed(err));
  }
}

function* handleCreateUserManagement(action) {
  try {
    const image = action.payload.fileUrl ? yield call(imageApi.postImage, action.payload.fileUrl) : '';

    yield call(userApi.createUser, {
      ...action.payload.formData,
      avatar: action.payload.fileUrl ? image.data.data.link : '',
    });
    yield put(userManagementAction.createUserSuccess(action.payload));
  } catch (error) {
    yield put(userManagementAction.createUserFailed(error.response.data.message));
  }
}

function* handleUpdateUserManagement(action) {
  try {
    const image = action.payload.fileUrl === 1 ? null : yield call(imageApi.postImage, action.payload.fileUrl);

    yield call(userApi.updateUser, {
      ...action.payload.formData,
      avatar: action.payload.fileUrl === 1 ? action?.payload?.updateImage || '' : image.data.data.link,
    });
    yield put(userManagementAction.updateUserSuccess(action.payload));
  } catch (error) {
    yield put(userManagementAction.updateUserFailed(error.response.data.message));
  }
}

function* handleDeleteUserManagement(action) {
  try {
    yield call(userApi.deleteUser, action.payload);
    yield put(userManagementAction.deleteUserSuccess(action.payload));
  } catch (error) {
    yield put(userManagementAction.deleteUserFailed(error.response.data.message));
  }
}

export default function* userManagementSaga() {
  yield takeLatest(userManagementAction.getAllUserManagement.type, handleGetAllUserManagement);
  yield takeLatest(userManagementAction.createUser.type, handleCreateUserManagement);
  yield takeLatest(userManagementAction.updateUser.type, handleUpdateUserManagement);
  yield takeLatest(userManagementAction.deleteUser.type, handleDeleteUserManagement);
}
