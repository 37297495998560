import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadFile } from 'src/features/superAdmin/file/thunk';
import { digLogAction } from 'src/features/digLog/slice';
// component
import InputField from 'src/components/inputField';
import ImagePicker from 'src/components/common/imagePicker/ImagePicker';
//Scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const TableServiceItem = (props) => {
  const dispatch = useDispatch();

  const { _id, key, name, img, error, onChange, onChangeImage, onDelete, onAddService } = props;
  const [isLoading, setIsLoading] = useState(false);

  const isActive = name?.trim() && img;
  const errorMessages = error
    ? Object.entries(error)
        .map(([key, message]) => message)
        .filter((message) => message)
    : [];

  const handleUploadImage = (file) => {
    if (!file) {
      onChange && onChange('img', null);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    setIsLoading(true);

    dispatch(uploadFile(formData))
      .unwrap()
      .then((res) => {
        const linkImage = res?.data?.link;
        if (linkImage) {
          onChange && onChange('img', linkImage);
        }
      })
      .catch((error) => {
        if (!error?.response?.data) return;
        const { message } = error?.response?.data;

        dispatch(
          digLogAction.getTitle({
            title: message,
            function: () => {},
          })
        );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div key={key} className={cx('itTableItemWrap')}>
        <div id='itItemService' className={cx('itTableItem')}>
          <div className={cx('itTbServiceName')}>
            <ImagePicker image={img} onChange={handleUploadImage} isLoading={isLoading} />
            <div className={cx('nameService')}>
              <InputField
                classNameInput={cx('crValueInput')}
                name={'serviceName'}
                value={name}
                placeholder='Service Name'
                onChange={(e) => onChange && onChange('name', e.target.value)}
              />
            </div>
          </div>

          {errorMessages?.length > 0 && (
            <p className={cx('itTableErrorItem', 'tablet')}>
              {errorMessages.map((message, index) => (
                <span key={index}>
                  {message}
                  {index < errorMessages.length - 1 && <br />}
                </span>
              ))}
            </p>
          )}

          <div className={cx('itItemActions')}>
            {/* <div
              className={cx('itBtnGroupEdit', 'deleteButton', `${!isActive && 'disable'}`)}
              onClick={() => {
                isActive && onDelete && onDelete();
              }}
            >
              Delete Service
            </div> */}

            <div
              className={cx('itBtnGroupEdit', `${!isActive && 'disable'}`)}
              onClick={() => {
                isActive && onAddService && onAddService();
              }}
            >
              Add Service
            </div>
          </div>
        </div>

        {errorMessages?.length > 0 && (
          <p className={cx('itTableErrorItem', 'desktop')}>
            {errorMessages.map((message, index) => (
              <span key={index}>
                {message}
                {index < errorMessages.length - 1 && <br />}
              </span>
            ))}
          </p>
        )}
      </div>
    </>
  );
};

export default TableServiceItem;
