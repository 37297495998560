import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { exactRouter } from 'src/utils/routers';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const CombineService = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === `${exactRouter.superAdmin.combineService}`) {
      navigate(`${exactRouter.superAdmin.listCombineService}`);
    }
  }, [navigate]);

  return (
    <div className={cx('wrapper')}>
      <Outlet />
    </div>
  );
};

export default CombineService;
