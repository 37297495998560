import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from 'src/app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from 'src/components/globalStyles';
import DigLog from './components/common/digLog/DigLog';
import DigLogComFig from './components/common/digLogComfig/DigLogComfig';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyles>
        <DigLog />
        <DigLogComFig />
        <App />
      </GlobalStyles>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function.
// to log results (for example: reportWebVitals(console.log)).
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
