import { call, put, takeLatest } from 'redux-saga/effects';
import subServiceApi from 'src/apis/superAdmin/subServiceApi';
import imageApi from 'src/apis/superAdmin/imageApi';
import { subServiceActions } from './slice';

function* handleGetAllSubService(action) {
  try {
    const response = yield call(subServiceApi.getAllSubService, action.payload);
    const data = response.data.data;
    yield put(subServiceActions.getAllSubServiceSuccess(data));
  } catch (err) {
    yield put(subServiceActions.getAllSubServiceFail(err?.responses?.data?.message));
  }
}

function* handleCreateSubService(action) {
  try {
    const image = yield call(imageApi.postImage, action.payload.fileUrl);
    const response = yield call(subServiceApi.postSubService, {
      ...action.payload.newServer,
      logo: image.data.data.link,
    });
    yield put(subServiceActions.createSubServiceSuccess(response));
  } catch (err) {
    yield put(subServiceActions.createSubServiceFail(err.response.data.message));
  }
}

function* handleDeleteSubService(action) {
  try {
    const response = yield call(subServiceApi.deleteSubService, action.payload);
    yield put(subServiceActions.deleteSubServiceSuccess(response));
  } catch (err) {
    yield put(subServiceActions.deleteSubServiceFailed(err.response.data.message));
  }
}

function* handleUpdateSubService(action) {
  try {
    const image = action.payload.fileUrl === 1 ? null : yield call(imageApi.postImage, action.payload.fileUrl);

    const response = yield call(subServiceApi.updateSubService, {
      ...action.payload.newServer,
      logo: action.payload.fileUrl === 1 ? action.payload.logoUpdate : image?.data?.data?.link,
    });
    yield put(subServiceActions.updateSubServiceSuccess(response));
  } catch (err) {
    yield put(subServiceActions.updateSubServiceFail(err.response.data.message));
  }
}

export default function* serviceSaga() {
  yield takeLatest(subServiceActions.getAllSubService.type, handleGetAllSubService);
  yield takeLatest(subServiceActions.createSubService.type, handleCreateSubService);
  yield takeLatest(subServiceActions.deleteSubService.type, handleDeleteSubService);
  yield takeLatest(subServiceActions.updateSubService.type, handleUpdateSubService);
}
