import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Slice
import {
  combineServiceActions,
  selectCombineServiceLoading,
  selectCombineServiceShowMessage,
} from 'src/features/superAdmin/combineService/slice';

// Utils
import { statusUser } from 'src/utils/enum';
// scss
import styles from './styles.module.scss';

// icon
import iconClose from 'src/assets/icons/ic_close.svg';
import InputField from 'src/components/inputField';
import { useForm } from 'react-hook-form';
import Dropdown from 'src/components/dropdown';
import TextareaField from 'src/components/textareaField';

const UpdateCombineService = (props) => {
  const { showCombine, setShowCombine, combineServiceEdit } = props;
  const [up, setUp] = useState(false);
  const cx = classNames.bind(styles);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectCombineServiceLoading);
  const isShowMessage = useSelector(selectCombineServiceShowMessage);

  const [status, setStatus] = useState();
  useEffect(() => {
    if (combineServiceEdit) {
      setStatus(combineServiceEdit.isActive === true ? 'ACTIVE' : 'INACTIVE');
    }
  }, [up, combineServiceEdit]);

  useEffect(() => {
    reset(combineServiceEdit);
  }, [combineServiceEdit, reset]);

  const handleCloseSidebar = () => {
    setShowCombine(false);
    reset(combineServiceEdit);
    setStatus(null);
    setUp(!up);
  };

  const onUpdate = (data) => {
    const payload = {
      _id: data._id,
      body: {
        offer: data.offer,
        isActive: status === 'ACTIVE' ? true : false,
        note: data.note?.trim(),
        emptySlot1: data.emptySlot1?.trim(),
        emptySlot2: data.emptySlot2?.trim(),
        emptySlot3: data.emptySlot3?.trim(),
        emptySlot4: data.emptySlot4?.trim(),
        emptySlot5: data.emptySlot5?.trim(),
      },
    };

    dispatch(combineServiceActions.updateCombineService(payload));
    setUp(!up);
  };

  return (
    <>
      <div className={`${cx('cbCombineContainer')} ${showCombine ? cx('cbCombineContainerShow') : cx('')}`}>
        <div className={cx('cbContent')}>
          <div className={cx('cbClose')}>
            <div className={cx('cbButton')} onClick={handleCloseSidebar}>
              <img src={iconClose} alt='iconClose' />
            </div>
          </div>
          <h3 className={cx('cbTitle')}>{combineServiceEdit?.name}</h3>

          <form className={cx('cbFormWrap')} onSubmit={handleSubmit(onUpdate)}>
            <div className={cx('crInputGroup')}>
              <InputField
                classNameWrap={cx('crFormInput')}
                classNameLabel={cx('crTitleInput')}
                classNameInput={cx('crValueInput')}
                label={'Offer(%)*'}
                register={register}
                name={'offer'}
                type={'number'}
                min={1}
                max={100}
                onChange={(e) => setValue('offer', e.target.value)}
                // validation={{
                //   required: 'Offer must be between 1 and 100',
                //   pattern: {
                //     value: OFFER_PERCENT_REGEX,
                //     message: 'Offer must be between 1 and 100',
                //   },
                // }}
                error={errors['offer']}
              />

              <div className={cx('crFormInput')}>
                <label htmlFor='input' className={cx('crTitleInput')}>
                  Status
                </label>
                <Dropdown
                  register={register}
                  name={'isActive'}
                  selected={statusUser.values}
                  options={status}
                  up={up}
                  setOptions={setStatus}
                  valueUpdate={combineServiceEdit?.isActive ? 'Active' : 'Inactive'}
                />
              </div>
              <TextareaField
                classNameWrap={cx('crFormTextarea')}
                label={'Note'}
                classNameLabel={cx('crTitleTextarea')}
                register={register}
                name={'note'}
                onChange={(e) => setValue('note', e.target.value)}
                classNameTextarea={`${cx('crValueTextarea')}`}
              />
              <InputField
                classNameWrap={cx('crFormInput')}
                label={'Empty Slot'}
                classNameLabel={cx('crTitleInput')}
                register={register}
                name={'emptySlot1'}
                type={'text'}
                onChange={(e) => setValue('emptySlot1', e.target.value)}
                classNameInput={`${cx('crValueInput')} ${cx('crValueServiceName')}`}
              />
              <InputField
                classNameWrap={cx('crFormInput')}
                label={'Empty Slot'}
                classNameLabel={cx('crTitleInput')}
                register={register}
                name={'emptySlot2'}
                type={'text'}
                onChange={(e) => setValue('emptySlot2', e.target.value)}
                classNameInput={`${cx('crValueInput')} ${cx('crValueServiceName')}`}
              />
              <InputField
                classNameWrap={cx('crFormInput')}
                label={'Empty Slot'}
                classNameLabel={cx('crTitleInput')}
                register={register}
                name={'emptySlot3'}
                type={'text'}
                onChange={(e) => setValue('emptySlot3', e.target.value)}
                classNameInput={`${cx('crValueInput')} ${cx('crValueServiceName')}`}
              />
              <InputField
                classNameWrap={cx('crFormInput')}
                label={'Empty Slot'}
                classNameLabel={cx('crTitleInput')}
                register={register}
                name={'emptySlot4'}
                type={'text'}
                onChange={(e) => setValue('emptySlot4', e.target.value)}
                classNameInput={`${cx('crValueInput')} ${cx('crValueServiceName')}`}
              />
              <InputField
                classNameWrap={cx('crFormInput')}
                label={'Empty Slot'}
                classNameLabel={cx('crTitleInput')}
                register={register}
                name={'emptySlot5'}
                type={'text'}
                onChange={(e) => setValue('emptySlot5', e.target.value)}
                classNameInput={`${cx('crValueInput')} ${cx('crValueServiceName')}`}
              />
            </div>
            <div className={cx('cbWrapperBtn')}>
              <button type='submit' disabled={loading || isShowMessage} className={cx('btnUpdate')}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
      {showCombine && <div className={cx('wrapperContent')} onClick={handleCloseSidebar}></div>}
    </>
  );
};

export default UpdateCombineService;
