import classNames from 'classnames/bind';
import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//import slide
import { serviceActions, selectServiceShowAdd } from 'src/features/superAdmin/service/slice';
import { digLogAction } from 'src/features/digLog/slice';
//import component
import CreateService from 'src/pages/superAdmin/service/create';
//import icon
import iconEdit from 'src/assets/icons/ic_edit.svg';
import iconDelete from 'src/assets/icons/ic_delete.svg';
import iconDeleteBox from 'src/assets/icons/ic_delete_box.svg';
import iconCheckBox from 'src/assets/icons/ic_check_box.svg';
//import image
import User from 'src/assets/images/img_logo_clean_window.png';
//import scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const TableHeaderMob = (props) => {
  const dispatch = useDispatch();

  const addShow = useSelector(selectServiceShowAdd);

  const [showService, setShowService] = useState(false);
  const [listService, setListService] = useState();

  const { _id, name, img, lOther, note, emptySlot1, emptySlot2, emptySlot3, emptySlot4, emptySlot5 } = props;

  const editService = () => {
    setListService({
      name: name,
      id: _id,
      img: img,
      note: note,
      emptySlot1: emptySlot1,
      emptySlot2: emptySlot2,
      emptySlot3: emptySlot3,
      emptySlot4: emptySlot4,
      emptySlot5: emptySlot5,
    });
    setShowService(true);
  };

  const deleteService = () => {
    dispatch(
      digLogAction.getComFig({
        title: `Delete ${name}`,
        function: () => {
          dispatch(serviceActions.deleteService(_id));
        },
      })
    );
  };

  useEffect(() => {
    if (addShow === true) {
      setShowService(false);
    }
  }, [addShow]);

  return (
    <div id='itHdHeaderItemMob' className={cx('itHdTableBlock')}>
      <div className={cx('itHdItemActions')}>
        <div className={cx('itHdBtnGroupDelete')}>
          <img src={iconDelete} alt='trashIcon' onClick={deleteService} className={cx('itHdButtonDelete')} />
        </div>
        <div className={cx('itHdBtnGroupEdit')}>
          <img src={iconEdit} alt='editIcon' onClick={editService} className={cx('itHdButtonEdit')} />
        </div>
      </div>
      <div className={cx('itHdServiceImg')}>
        <img
          src={img || User}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = User;
          }}
          alt=''
          className={cx('itHdImg')}
        />
      </div>
      <div className={cx('itHdTableItem')}>
        <div className={cx('itName')}>Service Name</div>
        <div className={cx('itTableItem')}>{name || 'N/A'}</div>
      </div>

      <div>
        <CreateService showService={showService} setShowSidebar={setShowService} title={true} listEdit={listService} />
      </div>
    </div>
  );
};

export default TableHeaderMob;
