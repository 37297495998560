import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.module.scss';
const cx = classNames.bind(styles);

const NoDataAvailable = () => {
  return (
    <div className={cx('noData')}>
      {' '}
      <div>
        <h2 className={cx('nameData')}> No Data Available</h2>
      </div>
    </div>
  );
};

export default NoDataAvailable;
