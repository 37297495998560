import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectAuthToken } from 'src/features/auth/authSlice';
import { exactRouter } from 'src/utils/routers';

const ProtectedRoutes = (props) => {
  const { Component } = props;
  const token = useSelector(selectAuthToken);

  return token ? <Component /> : <Navigate to={`${exactRouter.auth}`} />;
};

export default ProtectedRoutes;
