import React from 'react';
import { Navigate } from 'react-router-dom';

const MainLayout = () => {
  return (
    <>
      <Navigate to={`auth`} />
    </>
  );
};

export default MainLayout;
