import classNames from 'classnames/bind';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectAuthRole } from 'src/features/auth/authSlice';
// Slice
import { requestActions, selectFilterRequest, selectIsSuccessRequest } from 'src/features/superAdmin/requests/slice';
import { digLogAction } from 'src/features/digLog/slice';

//import components

// Utils
import { convertTimeRequestToLocal } from 'src/utils/help';
import { FilterStatusRequest } from 'src/utils/enum';
import { routerPaths } from 'src/utils/routers';
import { CheckRoleUser } from 'src/utils/enum';
import { contentDialog } from 'src/utils/contentDialog';
import { NOT_PERMISSION_DELETE } from 'src/utils/constants';

//import icon
import iconEyeShow from 'src/assets/icons/ic_eye_show.svg';
import iconDelete from 'src/assets/icons/ic_delete.svg';

//import image

//scss
import styles from './styles.module.scss';

const TableItemRequest = (props) => {
  const propsItem = props.props;

  const cx = classNames.bind(styles);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dateTime = convertTimeRequestToLocal(propsItem?.calendar);

  const status = useSelector(selectIsSuccessRequest);
  const filter = useSelector(selectFilterRequest);
  const role = useSelector(selectAuthRole);
  const isEmployee = role === CheckRoleUser.EMPLOYEE;

  const { householdId, nameTypeService } = props.props;

  // useEffect(() => {
  //   if (status === true) {
  //     dispatch(requestActions.getAllRequest({ ...filter }));
  //   }
  // }, [dispatch, filter, status]);

  // Handle logic
  const handleComplete = (event) => {
    // dispatch(requestActions.updateRequest({ _id: propsItem._id, body: { status: event.target.value } }));
    dispatch(
      digLogAction.getComFig({
        title: 'Are you sure you want to mark this request as complete?',
        function: () => {
          dispatch(
            requestActions.updateRequest({ _id: propsItem._id, body: { status: event.target.value }, navigate })
          );
        },
        buttonTitle: {
          oke: 'Ok',
          cancel: 'Cancel',
        },
      })
    );
  };

  const handleViewOrder = () => {
    if (!isEmployee) {
      navigate(`/${routerPaths.SUPER_ADMIN}/${routerPaths.ORDERS}/${propsItem._id}`);
    } else {
      navigate(`/${routerPaths.EMPLOYEE}/${routerPaths.ORDERS}/${propsItem._id}`);
    }
  };

  const handleDeleteOrder = () => {
    if (isEmployee) {
      dispatch(
        digLogAction.getTitle({
          title: NOT_PERMISSION_DELETE,
          function: () => {},
        })
      );
      return;
    }

    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE_ORDER.CLICK,
        function: () => {
          dispatch(requestActions.deleteRequest({ _id: propsItem._id, isActive: false }));
        },
      })
    );
  };

  return (
    <>
      <div className={cx('itHhTableItem')}>
        <p
          className={cx('itHhTbRequest')}
          title={
            propsItem?.infoHouseHold?.name?.firstName.concat(' ', propsItem?.infoHouseHold?.name?.lastName) || 'N/A'
          }
        >
          {propsItem?.infoHouseHold?.name?.firstName.concat(' ', propsItem?.infoHouseHold?.name?.lastName) || 'N/A'}
        </p>

        <p className={cx('itHhTbRequest')} title={propsItem?.infoHouseHold?.phoneNumber?.phone || 'N/A'}>
          {propsItem?.infoHouseHold?.phoneNumber?.phone || 'N/A'}
        </p>

        <p className={cx('itHhTbRequest')} title={propsItem?.infoHouseHold?.address || 'N/A'}>
          {propsItem?.infoHouseHold?.address || 'N/A'}
        </p>

        <p className={cx('itHhTbRequest')} title={dateTime || 'N/A'}>
          {dateTime || 'N/A'}
        </p>

        <div className={cx('itHhItemRequest')}>
          {propsItem.status === FilterStatusRequest.keys.INCOMPLETE ? (
            <button
              className={cx('rqBntActive')}
              // onClick={handleComplete}
              value={FilterStatusRequest.keys.COMPLETE}
            >
              Incomplete
            </button>
          ) : (
            <button
              className={cx('rqBntIsActive')}
              // onClick={handleComplete}
              // value={FilterStatusRequest.keys.INCOMPLETE}
            >
              Complete
            </button>
          )}
        </div>

        <div className={cx('itHhItemRequestActions')}>
          <div className={cx('itHhBtnGroupEdit')} onClick={handleViewOrder}>
            <img src={iconEyeShow} alt='editIcon' className={cx('itHhButtonEdit')} />
          </div>

          <div className={cx('itHhBtnGroupDelete')} onClick={handleDeleteOrder}>
            <img src={iconDelete} alt='iconDelete' className={cx('itHhButtonDelete')} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TableItemRequest;
