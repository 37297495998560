import axiosClient from '../axiosClient';
import { householdUrl, serviceUrl } from 'src/utils/constants';

const householdApi = {
  getAllHousehold(params) {
    const url = `${householdUrl.getAllHouseHold}`;
    return axiosClient.get(url, { params });
  },

  createHouseHold(params) {
    const url = `${householdUrl.getAllHouseHold}`;
    return axiosClient.post(url, params);
  },

  getDetailHousehold(params) {
    const url = `${householdUrl.getAllHouseHold}/${params._id}`;
    return axiosClient.get(url, params);
  },

  deleteHousehold(params) {
    const url = `${householdUrl.getAllHouseHold}/${params._id}`;
    return axiosClient.delete(url, params);
  },

  updateHousehold(params) {
    const url = `${householdUrl.getAllHouseHold}/${params._id}`;
    return axiosClient.put(url, params);
  },

  updateHouseholdServices(params) {
    const { _id, ...restParams } = params;
    const url = `${householdUrl.getAllHouseHold}/${_id}/${serviceUrl.putService}`;
    return axiosClient.put(url, restParams);
  },
};

export default householdApi;
