import classNames from 'classnames/bind';
import { useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { exactRouter } from 'src/utils/routers';
// import component
import Search from 'src/components/common/search';
import Filter from 'src/components/common/filter/Filter';
import CreateService from '../create';

// import component common
import TableHeader from 'src/components/common/table/tableHeaderSercive/TableHeader';
import TableItem from 'src/components/common/table/tableItem/tableItem';
import TableHeaderMob from 'src/components/common/table/tablemob/Tablemode';
import PagiNation from 'src/components/common/pagination/Pagination';
//utils
import { serviceFilter } from 'src/utils/enum';

//scss
import styles from './styles.module.scss';
const cx = classNames.bind(styles);

const ListTow = () => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState(serviceFilter.values.All);
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div>
      {showSidebar ? <CreateService setShowSidebar={setShowSidebar} /> : null}
      <div className={cx('lService')}>
        Service :
        <button className={cx('lBtnAddService')}>
          <p>+</p> <div> Add New Service</div>
        </button>
      </div>
      <div>
        <button
          className={cx('lBntTab')}
          onClick={() => {
            navigate(`${exactRouter.superAdmin.listService}`);
          }}
        >
          Service
        </button>
        <button
          className={cx('lBntTabHousehold')}
          onClick={() => {
            navigate(`${exactRouter.superAdmin.listTowService}`);
          }}
        >
          Requests
        </button>
      </div>
      <div className={cx('lBlock')}>
        <div className={cx('lFilters')}>
          <div>
            <Filter filter={serviceFilter.values} selected={selected} setSelected={setSelected} />
          </div>
          <div>
            <Search />
          </div>
        </div>
        <div className={cx('lItemDesktop')}>
          <TableHeader lOther={true} />
          {[...Array(9)].map((_, i) => (
            <TableItem key={i} lOther={true} />
          ))}
        </div>
        <div className={cx('lItemMob')}>
          {[...Array(6)].map((_, i) => (
            <TableHeaderMob key={i} lOther={true} />
          ))}
        </div>
        <div>
          <PagiNation />
        </div>
      </div>
    </div>
  );
};

export default ListTow;
