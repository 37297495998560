import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// component
import TableServiceItem from './tableItem/TableServiceItem';
import NoDataAvailable from 'src/components/common/noDataAvailable/NoDataAvaliable';
// others
import {
  selectServiceList,
  selectServiceLoading,
  selectServiceMessage,
  selectServiceShowMessage,
  selectServiceTypeAction,
  serviceActions,
} from 'src/features/superAdmin/service/slice';
import { digLogAction } from 'src/features/digLog/slice';
import { selectAuthRole } from 'src/features/auth/authSlice';
// Utils
import { MAX_SERVICE_PER_HOUSEHOLD } from 'src/utils/constants';
import { contentDialog } from 'src/utils/contentDialog';
import { routerPaths } from 'src/utils/routers';
import { handleErrorMessages } from 'src/utils/help';
import { CheckRoleUser } from 'src/utils/enum';
// icons
import iconNextDown from 'src/assets/icons/ic_next_down.svg';
// scss
import styles from './styles.modules.scss';

const HouseHoldServicesForm = ({ houseHoldId }) => {
  // Util
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const listService = useSelector(selectServiceList);
  const loading = useSelector(selectServiceLoading);
  const isShowMessage = useSelector(selectServiceShowMessage);
  const message = useSelector(selectServiceMessage);
  const typeAction = useSelector(selectServiceTypeAction);
  const role = useSelector(selectAuthRole);
  const isEmployee = role === CheckRoleUser.EMPLOYEE;

  useEffect(() => {
    return () => {
      dispatch(serviceActions.resetState());
    };
  }, []);

  useEffect(() => {
    if (isShowMessage === true && typeAction === 'DELETE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.DELETE_SERVICE.SUCCESS,
          function: () => {
            dispatch(serviceActions.resetStatus());
            dispatch(serviceActions.getAllService({ houseHoldId, limit: MAX_SERVICE_PER_HOUSEHOLD }));
          },
        })
      );
    } else if (isShowMessage === false) {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            dispatch(serviceActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, isShowMessage, message, typeAction]);

  useEffect(() => {
    dispatch(serviceActions.getAllService({ houseHoldId, limit: MAX_SERVICE_PER_HOUSEHOLD }));
  }, [dispatch]);

  const handleClickCustomerList = () => {
    if (!isEmployee) {
      navigate(`/${routerPaths.SUPER_ADMIN}/${routerPaths.HOUSEHOLDS}/${routerPaths.LIST_HOUSE}`);
    } else {
      navigate(`/${routerPaths.EMPLOYEE}/${routerPaths.HOUSEHOLDS}/${routerPaths.LIST_HOUSE}`);
    }
  };

  const handleAddNewService = () => {
    navigate(`/${routerPaths.SUPER_ADMIN}/${routerPaths.HOUSEHOLDS}/${houseHoldId}/${routerPaths.SERVICES}`);
  };

  const onDeleteService = (service) => {
    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE_SERVICE.CLICK,
        function: () => {
          dispatch(serviceActions.deleteService(service._id));
        },
      })
    );
  };

  const onEditService = (service) => {
    navigate(
      `/${routerPaths.SUPER_ADMIN}/${routerPaths.HOUSEHOLDS}/${houseHoldId}/${routerPaths.SERVICES}/${service._id}/${routerPaths.SUB_SERVICES}`
    );
  };

  return (
    <>
      <div className={cx('lContainerWrapper')}>
        <div className={cx('lBlock')}>
          <div className={cx('lItemDesktop')}>
            <div className={cx('lItemWrapper')}>
              {listService.length > 0 ? (
                listService.map((value, i) => (
                  <TableServiceItem
                    key={value._id || value.tempKey}
                    {...value}
                    delete={true}
                    onDelete={() => onDeleteService(value)}
                    onEditService={() => onEditService(value)}
                  />
                ))
              ) : (
                <NoDataAvailable />
              )}
            </div>
          </div>
        </div>

        <div className={cx('buttonContainer')}>
          <div className={cx('backButtonWrapper')} onClick={handleClickCustomerList}>
            <img alt='' src={iconNextDown} />
            <p>Customer List</p>
          </div>

          <div className={cx('actionButtonWrapper')}>
            <button
              type='button'
              disabled={loading || isShowMessage || isEmployee}
              className={cx('formButton', `${isEmployee && 'disable'}`)}
              onClick={handleAddNewService}
            >
              Add New Service
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HouseHoldServicesForm;
