import axiosClient from '../axiosClient';
import { combineServiceUrl } from 'src/utils/constants';

const combineServiceApi = {
  getAllCombineService(params) {
    const url = `${combineServiceUrl.getAllCombineService}`;
    return axiosClient.get(url, { params });
  },

  createCombineService(params) {
    const url = `${combineServiceUrl.basic}`;

    return axiosClient.post(url, params);
  },

  updateCombineService(params) {
    const url = `${combineServiceUrl.basic}/${params._id}`;
    return axiosClient.put(url, params.body);
  },

  deleteCombineService(id) {
    const url = `${combineServiceUrl.basic}/${id}`;
    return axiosClient.delete(url, id);
  },
};

export default combineServiceApi;
