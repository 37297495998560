import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// Routers
import { exactRouter } from 'src/utils/routers';

import styles from './styles.module.scss';
const cx = classNames.bind(styles);
const Orders = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === `${exactRouter.superAdmin.orders}`) {
      navigate(`${exactRouter.superAdmin.listOrders}`);
    } else if (location.pathname === `${exactRouter.admin.orders}`) {
      navigate(`${exactRouter.admin.listOrders}`);
    }
  }, [location.pathname, navigate]);

  return (
    <div className={cx('wrapper')}>
      <Outlet />
    </div>
  );
};

export default Orders;
