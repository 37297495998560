import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';

import helpFunction from 'src/utils/help';

import DownIcon from 'src/assets/icons/ic_down.svg';
import upIcon from 'src/assets/icons/ic_top.svg';

import styles from './styles.module.scss';
import { useOutside } from 'src/utils/help';

// or less ideally

const Dropdown = (props) => {
  const {
    selected,
    options,
    setOptions,
    up,
    name,
    valueUpdate,
    valueUpdateProvince,
    show,
    index,
    setshow,
    timePicker,
  } = props;

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const cx = classNames.bind(styles);
  const status = useRef();

  const [dropdownShow, setDropdownShow] = useState(show === undefined ? show : false);

  // console.log(`[Dropdown] => dropdownShow: `, dropdownShow);
  // console.log(`[Dropdown] => show: `, show);

  useEffect(() => {
    setDropdownShow(show);
  }, [show]);

  useEffect(() => {
    setDropdownShow(false);
  }, [up]);

  const handleOpenDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  // helpFunction.useOutside(status, setIsOpenDropdown);
  // useOutside(status, setDropdownShow);

  return (
    <div className={cx('drDropdown')} id='drDropdown'>
      <div className={cx('drFlowDropdown')} onClick={handleOpenDropdown}>
        <div
          className={cx('drDropdownBtn')}
          onClick={() => {
            if (typeof setshow === 'function') setshow(index);
            setDropdownShow(!dropdownShow);
          }}
        >
          {timePicker ? (
            <span className={cx('drTitleOptions')}>{options}</span>
          ) : (
            <span className={cx('drTitleOptions')}>
              {selected[options] || valueUpdate || valueUpdateProvince || Object.values(selected)[0]}
            </span>
          )}
          <img src={!dropdownShow ? DownIcon : upIcon} alt='DownIcon' className={cx('drDownUp')} />
        </div>
      </div>

      {dropdownShow && !timePicker && (
        <ul className={cx('drWrapContent')}>
          {Object.keys(selected).map((value, idx) => (
            <li
              name={name}
              className={`${cx('drItemOptions')} ${value === options && cx('drItemSelected')}`}
              key={idx}
              register={value}
              onClick={() => {
                setOptions(value);
                setDropdownShow(false);
              }}
            >
              {selected[value]}
            </li>
          ))}
        </ul>
      )}

      {dropdownShow && timePicker && (
        <ul className={cx('drWrapContent')}>
          {selected.map((value, idx) => (
            <li
              name={name}
              className={`${cx('drItemOptions')} ${value === options && cx('drItemSelected')}`}
              key={idx}
              register={value}
              onClick={() => {
                setOptions(value);
                setDropdownShow(false);
              }}
            >
              {value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
