import classNames from 'classnames/bind';
import React from 'react';

// scss
import styles from './styles.module.scss';

const InputField = (props) => {
  const {
    register,
    name,
    placeholder,
    type,
    classNameWrap,
    min,
    max,
    setIsTypeService,
    classNameInput,
    label,
    classNameLabel,
    classNameWrapInput,
    valueAsNumber,
    onChange,
    value,
    readonly,
    check,
    validation,
    disabled,
    error,
  } = props;
  const cx = classNames.bind(styles);

  return (
    <div className={cx(`${classNameWrap}`)}>
      {label && <label className={cx(`${classNameLabel}`)}>{label}</label>}
      <div className={cx(`${classNameWrapInput ?? 'wrapInput'}`)}>
        {register ? (
          <>
            {type !== 'number' ? (
              <input
                spellCheck='false'
                type={type}
                {...register(`${name}`, validation)}
                minLength={min}
                maxLength={max}
                className={cx(`${classNameInput}`)}
                placeholder={placeholder}
                readOnly={readonly}
                onChange={(e) => onChange && onChange(e)}
                value={value && value}
                disabled={disabled}
              />
            ) : (
              <input
                type={type}
                min={min}
                max={max}
                {...register(`${name}`, validation)}
                // valueAsNumber={valueAsNumber}
                className={cx(`${classNameInput}`)}
                placeholder={placeholder}
                onChange={(e) => onChange && onChange(e)}
                value={value && value}
                readOnly={readonly}
                step='0.01'
                disabled={disabled}
              />
            )}
          </>
        ) : (
          <input
            type={type}
            onChange={(e) => {
              setIsTypeService && setIsTypeService(e.target.checked);
              onChange && onChange(e);
            }}
            checked={check}
            className={cx(`${classNameInput}`)}
            placeholder={placeholder}
            value={value && value}
            disabled={disabled}
          />
        )}
        {error && <p className={cx('ifValidateText')}>{error.message}</p>}
      </div>
    </div>
  );
};

export default InputField;
