import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  listHouseholds: [],
  houseHoldData: null,
  isSuccess: false,
  total: null,
  show: false,
  isShowMessage: null,
  typeAction: '',
  message: '',
  filterEntry: {
    page: 1,
    limit: 10,
    total: 0,
    houseSize: '',
    textSearch: '',
  },
};

const householdSlice = createSlice({
  name: 'household',
  initialState,
  reducers: {
    getAllHousehold(state, action) {
      console.log(`[Household<Slice>] action.payload -> ${JSON.stringify(action.payload, null, 2)}`);
      state.loading = action.payload.isSocket ? false : true;
      state.isSuccess = false;
    },
    getAllHouseholdSuccess(state, action) {
      state.loading = false;
      state.listHouseholds = action.payload.households;
      state.total = action.payload.total;
      state.filterEntry.limit = action.payload.pagination.limit;
      state.filterEntry.page = action.payload.pagination.page;
      state.filterEntry.total = action.payload.pagination.total;
    },
    getAllHouseholdFail(state, action) {
      state.loading = false;
    },

    // CREATE HOUSEHOLD
    createHousehold(state, action) {
      state.loading = true;
      state.isSuccess = false;
    },
    createHouseholdSuccess(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.show = true;
      state.createSuccess = true;
      state.isShowMessage = true;
      state.typeAction = 'CREATE';
    },
    createHouseholdFail(state, action) {
      state.loading = false;
      state.isSuccess = false;
      state.show = false;
      state.createSuccess = false;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },

    // GET DETAIL HOUSEHOLD
    getDetailHousehold(state, action) {
      state.loading = true;
      state.isSuccess = false;
    },
    getDetailHouseholdSuccess(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.houseHoldData = action.payload;
    },
    getDetailHouseholdFail(state, action) {
      state.loading = false;
      state.isSuccess = false;
      state.isShowMessage = false;
    },

    // DELETE HOUSEHOLD
    deleteHousehold(state, action) {
      state.loading = true;
    },
    deleteHouseholdSuccess(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.isShowMessage = true;
      state.typeAction = 'DELETE';
    },
    deleteHouseholdFail(state, action) {
      state.loading = false;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },

    // UPDATE HOUSEHOLD
    updateHousehold(state, action) {
      state.loading = true;
      state.isSuccess = true;
    },
    updateHouseholdSuccess(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.show = true;
      state.isShowMessage = true;
      state.typeAction = 'UPDATE';
    },
    updateHouseholdFail(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.show = false;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },

    // UPDATE SERVICES IN HOUSEHOLD
    updateHouseHoldServices(state, action) {
      state.loading = true;
      state.isSuccess = true;
    },
    updateHouseHoldServicesSuccess(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.show = true;
      state.isShowMessage = true;
      state.typeAction = 'UPDATE_SERVICES';
    },
    updateHouseHoldServicesFail(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.show = false;
      state.isShowMessage = false;
      state.typeAction = 'UPDATE_SERVICES';
      state.message = action.payload;
    },

    filterHousehold(state, action) {
      state.filterEntry = action.payload;
    },
    openForm(state, action) {
      state.show = true;
    },
    cloneForm(state, action) {
      state.show = false;
    },

    resetState: () => initialState,
    resetStatus: (state) => {
      state.message = '';
      state.isShowMessage = null;
      state.typeAction = '';
    },
  },
});

export const houseActions = householdSlice.actions;

export const selectHouseholdIsSuccess = (state) => state.household.isSuccess;
export const selectHouseholdLoading = (state) => state.household.loading;
export const selectHouseholdFilter = (state) => state.household.filterEntry;
export const selectHouseholdTotal = (state) => state.household.total;
export const selectHouseholdOpenForm = (state) => state.household.show;
export const selectHouseholdList = (state) => state.household.listHouseholds;
export const selectHouseholdResetForm = (state) => state.household.resetForm;
export const selectHouseholdTypeAction = (state) => state.household.typeAction;
export const selectHouseholdShowMessage = (state) => state.household.isShowMessage;
export const selectHouseholdMessage = (state) => state.household.message;
export const selectHouseholdData = (state) => state.household.houseHoldData;

export const householdReduce = householdSlice.reducer;
export default householdReduce;
