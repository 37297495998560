// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import fileApi from 'src/apis/superAdmin/fileApi';
import { ActionType } from 'src/utils/enum';

export const uploadFile = createAsyncThunk(ActionType.UPLOAD_FILE, async (body, { rejectWithValue }) => {
  try {
    const res = await fileApi.uploadFile(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
