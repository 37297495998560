import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

//features
import { digLogAction } from 'src/features/digLog/slice';
import { uploadFile } from 'src/features/superAdmin/file/thunk';
//components
import ImagePicker from 'src/components/common/imagePicker/ImagePicker';
import InputField from 'src/components/inputField';
import TextareaField from 'src/components/textareaField';

//utils
import { contentDialog } from 'src/utils/contentDialog';

//Scss
import styles from '../styles.module.scss';

const cx = classNames.bind(styles);

const TableItem = (props) => {
  const { key, name, logo, isActive, price, notes, error, onChange, onDelete, disabled } = props;

  const { register } = useForm();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const isEnableButton = name?.trim() && logo;
  const errorMessages = error
    ? Object.entries(error)
        .map(([key, message]) => message)
        .filter((message) => message)
    : [];

  const deleteSubService = () => {
    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE_CONFIRM.CONFIRM(name),
        function: () => {
          onDelete && onDelete();
        },
      })
    );
  };

  const handleUploadImage = (file) => {
    if (!file) {
      onChange && onChange('logo', null);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    setIsLoading(true);

    dispatch(uploadFile(formData))
      .unwrap()
      .then((res) => {
        const linkImage = res?.data?.link;
        if (linkImage) {
          onChange && onChange('logo', linkImage);
        }
      })
      .catch((error) => {
        if (!error?.response?.data) return;
        const { message } = error?.response?.data;

        dispatch(
          digLogAction.getTitle({
            title: message,
            function: () => {},
          })
        );
      })
      .finally(() => setIsLoading(false));
  };

  const hanleChangeCheckbox = (value) => {
    if (value) {
      dispatch(
        digLogAction.getComFig({
          title: contentDialog.UPDATE_SUBSERVICE.PRICE,
          function: () => {
            onChange && onChange('isActive', value);
          },
          functionCancel: () => {},
        })
      );
      return;
    }

    onChange && onChange('isActive', value);
  };

  return (
    <>
      <div key={key} className={cx('itTableItemWrap')}>
        <div id='itItemSubService' className={cx('itTableItem')}>
          <div className={cx('itTbServiceName')}>
            <ImagePicker image={logo} onChange={handleUploadImage} isLoading={isLoading} disabled={disabled} />

            <div className={cx('nameService')}>
              <InputField
                classNameWrap={cx('crWrapInput')}
                classNameInput={cx('crValueInput')}
                name={'name'}
                value={name}
                placeholder='Sub-Service Name'
                onChange={(e) => onChange && onChange('name', e.target.value)}
                disabled={disabled}
              />
            </div>
          </div>

          <div className={cx('wrapPrice')}>
            <InputField
              classNameWrapInput={cx('crFormWrapCheckBox')}
              classNameWrap={cx('crFormCheckBox')}
              classNameInput={cx('crCheckedInput')}
              type={'checkbox'}
              setIsTypeService={hanleChangeCheckbox}
              check={isActive ?? false}
              disabled={disabled}
            />
            <div className={cx('containerPriceInput')}>
              <p className={cx('unitPriceInput')}>$</p>
              <InputField
                classNameWrap={cx('crPriceWrap')}
                classNameInput={cx('crPriceInput')}
                name={'price'}
                value={price}
                placeholder='Price'
                onChange={(e) => onChange && onChange('price', e.target.value)}
                disabled={disabled}
              />
            </div>
          </div>

          <TextareaField
            classNameWrap={cx('crFormTextarea')}
            classNameTextarea={`${cx('crValueTextarea')}`}
            placeholder={'Note'}
            type={'text'}
            max={50}
            register={register}
            name={'notes'}
            value={notes}
            onChange={(e) => onChange && onChange('notes', e.target.value)}
            disabled={disabled}
          />

          {errorMessages?.length > 0 && (
            <p className={cx('itTableErrorItem', 'tablet')}>
              {errorMessages.map((message, index) => (
                <span key={index}>
                  {message}
                  {index < errorMessages.length - 1 && <br />}
                </span>
              ))}
            </p>
          )}

          {/* <div className={cx('itItemActions')}>
            <div
              className={cx('itBtnGroupEdit', 'deleteButton', `${!isEnableButton && 'disable'}`)}
              onClick={() => {
                isEnableButton && deleteSubService();
              }}
            >
              Delete Sub-Service
            </div>
          </div> */}
        </div>
        {errorMessages?.length > 0 && (
          <p className={cx('itTableErrorItem', 'desktop')}>
            {errorMessages.map((message, index) => (
              <span key={index}>
                {message}
                {index < errorMessages.length - 1 && <br />}
              </span>
            ))}
          </p>
        )}
      </div>
    </>
  );
};

export default TableItem;
