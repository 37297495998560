import { useState, useEffect, useLayoutEffect } from 'react';
import dayjs from 'dayjs';
import { contentDialog, errorsResponse } from './contentDialog';

/**
 * Function Click Outside close
 * @param {*} ref -> Element handle click
 * @param {*} setOpen -> State set open
 * return -> Unbind the event listener on clean up
 */
export const useOutside = (ref, setOpen) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setOpen]);
};

/**
 * Get width on Resize screen
 * Handle resize mobile -> desktop
 * @returns Size
 */
export const useWidthSize = () => {
  const [size, setSize] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    // updateSize();
  }, []);

  return size;
};

const helpFunction = {
  useOutside,
};

//split name
/**
 *
 * @param {*} data String
 * @returns {
 * firstName,
 * lastName,
 * }
 */
export const splitName = (data) => {
  const firstName = data.split(' ')[0];
  const lastName = data.split(' ').slice(1).join(' ');
  return {
    firstName,
    lastName,
  };
};

//Uppercase first letter
export const capitalizeFirstLetter = (string) => {
  const lowercase = string ? string.toLowerCase() : '';
  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

//Format phone numbers

export function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

export const searchTextByName = (listSearch, text) => {
  return listSearch.filter((item) => item.name.search(new RegExp(text, 'i')) >= 0);
};

// Format Postal Code
export const formatPostalCode = (value) => {
  if (!value) return value;

  const postalCode = value.replace(/\s/g, '');

  const postalCodeLength = value.length;

  if (postalCodeLength <= 3) {
    return postalCode;
  }
  if (postalCodeLength > 3) {
    return `${postalCode.slice(0, 3)} ${postalCode.slice(3, 6)}`;
  }
};

export const convertTimeRequestToLocal = (time) => {
  const date = dayjs(time.startHour).format('DD/MM/YYYY');
  let dateTimeStart = dayjs(time.startHour).format('hh:mm a');
  let dateTimeEnd = dayjs(time.endHour).format('hh:mm a');

  return `${date}, ${dateTimeStart} - ${dateTimeEnd}`;
};

export const convertTimeCalendarRequestToLocal = (time) => {
  let dateTimeStart = dayjs(time.startHour).format('hh:mm a');
  let dateTimeEnd = dayjs(time.endHour).format('hh:mm a');

  return `${dateTimeStart} - ${dateTimeEnd}`;
};

export default helpFunction;

export const handleErrorMessages = (messageResponse) => {
  switch (messageResponse) {
    case errorsResponse.AUTH_ERROR.USER_NOT_FOUND: {
      return contentDialog.LOGIN_FAILED.USER_NOT_FOUND;
    }
    case errorsResponse.AUTH_ERROR.PASSWORD_INCORRECT: {
      return contentDialog.LOGIN_FAILED.PASSWORD_INCORRECT;
    }
    case errorsResponse.AUTH_ERROR.EMAIL_NOT_FOUND: {
      return contentDialog.SEND_LINK_FORGOT.EMAIL_NOT_FOUND;
    }
    case errorsResponse.AUTH_ERROR.EMAIL_IS_EXISTED: {
      return contentDialog.CREATE_ACCOUNT_ACTION.TITLE_FAILED;
    }
    case errorsResponse.HOUSEHOLD.HOUSEHOLD_PHONE_NOT_FOUND: {
      return contentDialog.HOUSER_HOLD.HOUSEHOLD_PHONE_NOT_FOUND;
    }
    case errorsResponse.HOUSEHOLD.HOUSEHOLD_NAME_NOT_FOUND: {
      return contentDialog.HOUSER_HOLD.HOUSEHOLD_NAME_NOT_FOUND;
    }
    case errorsResponse.HOUSEHOLD.HOUSEHOLD_MAIL_NOT_FOUND: {
      return contentDialog.HOUSER_HOLD.HOUSEHOLD_MAIL_NOT_FOUND;
    }
    case errorsResponse.SERVICES.SERVICES_ALREADY_EXIST: {
      return contentDialog.SERVICES.EMAIL_ALREADY_EXIST;
    }
    case errorsResponse.SERVICES.SERVICES_ERROR: {
      return contentDialog.CREATE_NEW_SERVICE.FAILED;
    }
    case errorsResponse.FORGOT_PASSWORD.FAILED: {
      return contentDialog.FORGOT_PASSWORDD.FAILED;
    }
    default: {
      return messageResponse ?? 'Something went wrong, please contact administrator!';
    }
  }
};

// Get current timezone
export const getCurrentTimezone = () => {
  const timezone = -(new Date().getTimezoneOffset() / 60);
  console.log(`[help][getCurrentTimezone] timezone -> ${timezone}`);

  /** Format timezone to offset (+00:00) */
  const formatTimezone =
    (timezone < 0 ? '-' : '+') + (Math.abs(timezone) < 10 ? `0${Math.abs(timezone)}` : timezone) + ':00';

  return formatTimezone;
};

// Convert array to object format
export const convertArrToObjectOptions = (arr) => {
  return arr.reduce((value, label) => {
    value[label._id] = label.name;
    return value;
  }, {});
};

// Return messages price
export const getTitleMessagePrice = (fieldName, message) => {
  return `${fieldName} ${message}`;
};

//
export const convertTimeRangeCalendar = (date, time) => {
  const [start, end] = time.split(' - ');
  const currentDate = dayjs(date).format('YYYY-MM-DD');
  const format = 'YYYY-MM-DD HH:mmZ';

  const startHour = dayjs(`${currentDate} ${start}`, 'YYYY-MM-DD hh:mm A').format(`${format}`);
  const endHour = dayjs(`${currentDate} ${end}`, 'YYYY-MM-DD hh:mm A').format(`${format}`);

  return {
    startHour,
    endHour,
  };
};

// Random String With Length
export const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
};
