// Library
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { Validator } from 'devextreme-react';
import { EmailRule, RequiredRule } from 'devextreme-react/data-grid';
import { PatternRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components

// Features
import { authActions, selectAuthMessage, selectAuthStatus } from 'src/features/auth/authSlice';
import { digLogAction } from 'src/features/digLog/slice';
// Utils
import { handleErrorMessages } from 'src/utils/help';
import { PASSWORD_REGEX } from 'src/utils/constants';
import { exactRouter } from 'src/utils/routers';

// Icons
import iconEyeHideIcon from 'src/assets/icons/ic_eye_hide.svg';
import iconEyeShowIcon from 'src/assets/icons/ic_eye_show.svg';

// Images

// Scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const Login = () => {
  const dispatch = useDispatch();

  const isStatus = useSelector(selectAuthStatus);
  const message = useSelector(selectAuthMessage);

  const [emailData, setEmailData] = useState('');
  const [passwordData, setPasswordData] = useState('');
  const [passwordMode, setPasswordMode] = useState('password');
  const [eyeIcon, setEyeIcon] = useState(iconEyeHideIcon);

  const passwordButton = {
    icon: eyeIcon,
    stylingMode: 'text',
    onClick: () => {
      setPasswordMode(passwordMode === 'text' ? 'password' : 'text');
      setEyeIcon(eyeIcon === iconEyeHideIcon ? iconEyeShowIcon : iconEyeHideIcon);
    },
  };

  const onEmailChange = (e) => {
    setEmailData(e.value);
  };

  const onPasswordChange = (e) => {
    setPasswordData(e.value);
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    const body = {
      email: emailData,
      password: passwordData,
    };
    if (isStatus === null) {
      dispatch(authActions.login(body));
    }
  };

  useEffect(() => {
    if (isStatus === false) {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            dispatch(authActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, isStatus, message]);

  return (
    <div className={cx('lg-wrapper')} id='login'>
      <div className={cx('lg-title')}>
        <div className={cx('lg-text')}>
          Welcome back <br></br> to <span>TADA</span>
        </div>
        <div className={cx('lg-desc')}>Please log in to enter TADA</div>
      </div>

      <form action='POST' className={cx('lg-form')} onSubmit={handleLoginClick}>
        <div className={cx('lg-field')}>
          <p className={cx('lg-required')}>Email*</p>
          <TextBox showClearButton={true} className={cx('lg-input')} value={emailData} onValueChanged={onEmailChange}>
            <Validator>
              <RequiredRule message='Please fill in a valid email address' />
              <EmailRule message='Please fill in a valid email address' />
            </Validator>
          </TextBox>
        </div>
        <div className={cx('lg-field')}>
          <p className={cx('lg-required')}>Password*</p>
          <TextBox
            mode={passwordMode}
            className={cx('lg-input')}
            value={passwordData}
            onValueChanged={onPasswordChange}
          >
            <Validator>
              <RequiredRule message='Password is required' />
              <PatternRule
                pattern={PASSWORD_REGEX}
                message='Password must be at least 8 characters and contain at least 1 uppercase character, 1 number, and 1 special character.'
              />
            </Validator>
            <TextBoxButton id='abc' name='password' location='after' options={passwordButton} />
          </TextBox>
        </div>

        <div className={cx('lgForgotPassword')}>
          <Link to={`${exactRouter.forgotPassword}`} className={cx('lg-forgot')}>
            Forgot Password?
          </Link>
        </div>

        <Button
          text='Log in'
          activeStateEnabled={false}
          focusStateEnabled={false}
          type='success'
          className={cx('lg-button')}
          useSubmitBehavior={true}
        />
      </form>
    </div>
  );
};

export default Login;
