import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

// scss
import styles from './style.module.scss';

const TabBar = ({ tabData }) => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    let initTabData = tabData;
    const hasTabActive = initTabData.some((e) => e.active);
    if (!hasTabActive && initTabData.length > 0) {
      initTabData[0].active = true;
    }

    setTabs(initTabData);
  }, [tabData]);

  const handleClickTab = (tab) => {
    navigate(`?tab=${tab.key}`);
    setTabs((prevData) => prevData.map((e) => ({ ...e, active: e.key === tab.key })));
  };

  return (
    <>
      <div className={cx('tab')}>
        {tabs.map((e) => {
          return (
            <button className={cx(`${e?.active && 'active'}`)} onClick={() => handleClickTab(e)}>
              {e.title}
            </button>
          );
        })}
      </div>

      {tabs.map((e) => {
        return (
          <div id={e?.key} className={cx('tabContent', `${e?.active && 'active'}`)}>
            {e?.component}
          </div>
        );
      })}
    </>
  );
};

export default TabBar;
