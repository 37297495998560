import React, { useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';

// Slice
import { authActions, selectUserProfile, selectAuthRole } from 'src/features/auth/authSlice';

// Icons
import iconDown from 'src/assets/icons/ic_down.svg';

//utils
import helpFunction from 'src/utils/help';

// Images
import imgAvatar from 'src/assets/images/img_avatar.jpg';

const cx = classNames.bind(styles);

const Header = () => {
  const name = JSON.parse(localStorage.getItem('user_profile'));

  const dispatch = useDispatch();
  const userProfile = useSelector(selectUserProfile);
  const userRole = useSelector(selectAuthRole);

  const logOut = useRef();
  const [showLogOut, setShowLogOut] = useState(false);

  const handleShowLogOut = () => {
    setShowLogOut(!showLogOut);
  };

  const handleLogOut = () => {
    dispatch(authActions.logout());
    setShowLogOut(!showLogOut);
  };
  helpFunction.useOutside(logOut, setShowLogOut);

  return (
    <header className={cx('hdHeader')} id='hdHeader'>
      <div className={cx('hdWrapper')} ref={logOut}>
        <div className={cx('hdUserProfile')} onClick={handleShowLogOut}>
          <img
            src={name?.staff?.avatar || imgAvatar}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = imgAvatar;
            }}
            alt=''
            className={cx('hdUserProfileImg')}
          />

          {/* <span className={cx('hdUsername')}>
            {userProfile?.staff?.name
              ? `${userProfile?.staff?.name?.firstName} ${userProfile?.staff?.name?.lastName}`
              : userProfile?.role}
          </span> */}
          {/* <span className={cx('hdUsername')}>
            {name?.staff?.name?.lastName} {name?.staff?.name?.firstName}
            {name?.staff?.name?.lastName || name?.staff?.name?.firstName ? '' : name?.role}
          </span> */}
          <img src={iconDown} alt='iconDown' className={cx('hdIconDown')} />
        </div>
        {showLogOut && (
          <ul className={cx('hdShowLogOut')}>
            <li className={cx('hdLogOut')} onClick={handleLogOut}>
              Log Out
            </li>
          </ul>
        )}
      </div>
    </header>
  );
};

export default Header;
