import classNames from 'classnames/bind';
import { useState, useEffect } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// component
import TableServiceItem from './table/tableItem/TableServiceItem';
import ListSubService from '../../subService/list/List';
//
import { selectServiceList, serviceActions } from 'src/features/superAdmin/service/slice';
import { digLogAction } from 'src/features/digLog/slice';
import {
  houseActions,
  selectHouseholdData,
  selectHouseholdLoading,
  selectHouseholdMessage,
  selectHouseholdShowMessage,
  selectHouseholdTypeAction,
} from 'src/features/superAdmin/household/slice';
//utils
import { contentDialog } from 'src/utils/contentDialog';
import {
  CreateHouseHoldStep,
  IMAGE_REQUIRED,
  MAX_SERVICE_PER_HOUSEHOLD,
  SERVICE_NAME_REQUIRED,
} from 'src/utils/constants';
import { generateRandomString, handleErrorMessages } from 'src/utils/help';
import { routerPaths } from 'src/utils/routers';

//scss
import styles from './styles.module.scss';
const cx = classNames.bind(styles);

const ListService = (props) => {
  const { initHouseholdData, initServices, onSave, onCancel } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { houseHoldId } = useParams();

  const [listConvertedService, setListConvertedService] = useState(initServices ?? []);
  const [listDeletedService, setListDeletedService] = useState([]);
  const [stepHouseHold, setStepHouseHold] = useState(CreateHouseHoldStep.LIST_SERVICE);
  const [currentServiceData, setCurrentServiceData] = useState();
  const [listDeletedSubService, setListDeletedSubService] = useState([]);
  const [householdData, setHouseholdData] = useState(initHouseholdData);

  /**
   * UseSelector Block
   */
  const listService = useSelector(selectServiceList);
  const initHouseHoldData = useSelector(selectHouseholdData);
  const loading = useSelector(selectHouseholdLoading);
  const isShowMessage = useSelector(selectHouseholdShowMessage);
  const message = useSelector(selectHouseholdMessage);
  const typeAction = useSelector(selectHouseholdTypeAction);

  /**
   * UseEffect Block
   */

  useEffect(() => {
    return () => {
      dispatch(houseActions.resetState());
      dispatch(serviceActions.resetState());
    };
  }, []);

  useEffect(() => {
    if (houseHoldId) {
      dispatch(houseActions.getDetailHousehold({ _id: houseHoldId }));
      dispatch(serviceActions.getAllService({ houseHoldId, limit: MAX_SERVICE_PER_HOUSEHOLD }));
    }
  }, [houseHoldId]);

  useEffect(() => {
    if (initHouseHoldData && houseHoldId) {
      setHouseholdData(initHouseHoldData);
    }
  }, [initHouseHoldData]);

  useEffect(() => {
    const initListServices = listService?.length ? listService : initServices;
    let updatedList = initListServices ? [...initListServices] : [];

    while (updatedList.length < MAX_SERVICE_PER_HOUSEHOLD) {
      updatedList.push({ tempKey: generateRandomString(10) });
    }

    setListConvertedService(updatedList);
  }, [listService]);

  useEffect(() => {
    if (isShowMessage === true && typeAction === 'UPDATE_SERVICES') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.UPDATE_SERVICES.SUCCESS,
          function: () => {
            dispatch(houseActions.resetStatus());
            navigate(
              `/${routerPaths.SUPER_ADMIN}/${routerPaths.HOUSEHOLDS}/${houseHoldId}/?tab=${routerPaths.SERVICES}`
            );
          },
        })
      );
    } else if (isShowMessage === false && typeAction === 'UPDATE_SERVICES') {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            dispatch(houseActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, isShowMessage, message, typeAction]);

  const hasServiceValue = (service) => service.img || service.name?.toString()?.trim();

  const onChangeValue = (indexService, field, value) => {
    let updatedList = listConvertedService.map((service, index) => {
      let newService = service;

      if (index === indexService) {
        newService = { ...newService, [field]: value };

        if (newService.error) {
          let newError = newService.error;
          if (value?.toString()?.trim()) {
            const { [field]: removedErrorField, ...restError } = newService.error;
            newError = restError;
          }

          newService.error = hasServiceValue(newService) ? newError : {};
        }
      }

      return newService;
    });

    setListConvertedService([...updatedList]);
  };

  const goToPreviousStep = () => {
    setStepHouseHold(CreateHouseHoldStep.LIST_SERVICE);
  };

  const onDeleteService = (indexService) => {
    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE_SERVICE.CLICK,
        function: () => {
          const deleteService = listConvertedService[indexService];
          if (deleteService?._id) {
            setListDeletedService((prevServices) => [...prevServices, deleteService?._id]);
          }

          let updatedList = listConvertedService.filter((_, index) => index !== indexService);

          while (updatedList.length < MAX_SERVICE_PER_HOUSEHOLD) {
            updatedList.push({ tempKey: generateRandomString(10) });
          }

          setListConvertedService(updatedList);
        },
        functionCancel: () => {},
      })
    );
  };

  const onAddSubService = (service) => {
    setCurrentServiceData(service);
    setStepHouseHold(CreateHouseHoldStep.LIST_SUB_SERVICE);
  };

  const handleSaveService = () => {
    /// Validate
    const updatedList = listConvertedService.map((item) => {
      let error = {};

      if (hasServiceValue(item)) {
        if (!item.img) error.img = IMAGE_REQUIRED;
        if (!item.name?.toString()?.trim()) error.name = SERVICE_NAME_REQUIRED;
      }

      return { ...item, error };
    });

    setListConvertedService(updatedList);

    const hasErrors = updatedList.some((item) => item.error && Object.keys(item.error).length > 0);
    if (hasErrors) {
      dispatch(digLogAction.getTitle({ title: 'Invalid Service Exists' }));
      return;
    }

    /// Add empty service to delete array
    const listEmptyServiceIds = [];
    listConvertedService.forEach((item) => {
      if (item._id && !item.name?.toString()?.trim() && !item.img) {
        listEmptyServiceIds.push(item._id);
      }
    });

    /// New Services
    const listNewService = listConvertedService
      .filter((value) => value.tempKey && value.name?.toString()?.trim() && value.img)
      .map(({ tempKey, error, ...rest }) => rest);

    /// Handle Update Services
    if (houseHoldId) {
      const listUpdateService = listService.reduce((acc, service) => {
        const matchingItem = listConvertedService
          .filter((item) => item._id && item.name?.toString()?.trim() && item.img)
          .find((item) => item._id === service._id);

        if (matchingItem) {
          const { error, household: matchingHousehold, ...restMatchingItem } = matchingItem;
          const { household: serviceHousehold, ...restServiceItem } = service;

          if (JSON.stringify(restServiceItem) !== JSON.stringify(restMatchingItem)) {
            acc.push(restMatchingItem);
          }
        }

        return acc;
      }, []);

      const payloadData = {
        _id: houseHoldId,
        services: [...listNewService, ...listUpdateService],
        deleteServiceIds: [...listDeletedService, ...listEmptyServiceIds],
        deleteSubServiceIds: listDeletedSubService,
      };

      dispatch(houseActions.updateHouseHoldServices(payloadData));
      return;
    }

    /// Handle Create HouseHold With Services, Sub-Services
    const payloadData = {
      ...householdData,
      services: listNewService,
    };

    dispatch(houseActions.createHousehold(payloadData));
    onSave && onSave();
  };

  const handleSaveSubService = (data) => {
    setListDeletedSubService((prevSubServices) => [...prevSubServices, ...data.deleteSubServiceIds]);
    setListConvertedService((prevServices) =>
      prevServices.map((service) =>
        (service._id || service.tempKey) === (currentServiceData._id || currentServiceData.tempKey)
          ? { ...service, subServices: data.subServices }
          : service
      )
    );
    goToPreviousStep();
  };

  const handleCancel = () => {
    if (houseHoldId) {
      navigate(-1);
      return;
    }

    onCancel && onCancel(listConvertedService);
  };

  const getListSubService = (listSubService) => {
    return listSubService.filter((item) => !listDeletedSubService.includes(item?._id));
  };

  return (
    <>
      {stepHouseHold === CreateHouseHoldStep.LIST_SERVICE && (
        <div className={cx('lContainerWrapper')}>
          <div className={cx('lService')}>
            <p className={cx('lServiceName')}>
              Household Services For: {householdData?.name?.firstName} {householdData?.name?.lastName}
            </p>
          </div>

          <div className={cx('lBlock')}>
            <div className={cx('lItemDesktop')}>
              <div className={cx('lItemWrapper')}>
                {listConvertedService.map((value, i) => (
                  <TableServiceItem
                    key={value._id || value.tempKey}
                    {...value}
                    delete={true}
                    onChange={(field, value) => onChangeValue(i, field, value)}
                    onDelete={() => onDeleteService(i)}
                    onAddService={() => onAddSubService(value)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className={cx('containerButton')}>
            <button type='button' className={cx('actionButton', 'buttonCancel')} onClick={handleCancel}>
              Cancel
            </button>

            <button type='button' className={cx('actionButton')} onClick={handleSaveService}>
              Save
            </button>
          </div>
        </div>
      )}

      {stepHouseHold === CreateHouseHoldStep.LIST_SUB_SERVICE && (
        <ListSubService
          initServiceData={currentServiceData}
          initListSubService={getListSubService(currentServiceData?.subServices ?? [])}
          onSave={handleSaveSubService}
          onCancel={goToPreviousStep}
        />
      )}
    </>
  );
};

export default ListService;
