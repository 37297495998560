import React from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
//
import { selectServiceLoading } from 'src/features/superAdmin/service/slice';
import { selectUserLoading } from 'src/features/superAdmin/userManagement/slice';
import { selectCombineServiceLoading } from 'src/features/superAdmin/combineService/slice';

import styles from './styles.module.scss';
import { selectLoadingRequest } from 'src/features/superAdmin/requests/slice';
import { selectHouseholdLoading } from 'src/features/superAdmin/household/slice';
import { selectAuthLoading } from 'src/features/auth/authSlice';
import { selectSubServiceLoading } from 'src/features/superAdmin/subService/slice';
const cx = classNames.bind(styles);

const Loading = () => {
  const loadingService = useSelector(selectServiceLoading);
  const loadingSubService = useSelector(selectSubServiceLoading);
  const loadingUser = useSelector(selectUserLoading);
  const loadingCombineService = useSelector(selectCombineServiceLoading);
  const loadingHouseholdRequest = useSelector(selectLoadingRequest);
  const loadingHousehold = useSelector(selectHouseholdLoading);
  const loadingAuth = useSelector(selectAuthLoading);

  return (
    <div>
      {loadingService ||
      loadingSubService ||
      loadingUser ||
      loadingCombineService ||
      loadingHousehold ||
      loadingHouseholdRequest ||
      loadingAuth ? (
        <div className={cx('Loading')}>
          <div className={cx('lds-spinner')}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Loading;
