import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthRole } from 'src/features/auth/authSlice';
// component
// others
import { digLogAction } from 'src/features/digLog/slice';
import {
  requestActions,
  selectIsShowMessage,
  selectRequestData,
  selectRequestMessage,
  selectTypeAction,
} from 'src/features/superAdmin/requests/slice';
// Utils
import { FilterStatusRequest } from 'src/utils/enum';
import { routerPaths } from 'src/utils/routers';
import { contentDialog } from 'src/utils/contentDialog';
import { handleErrorMessages } from 'src/utils/help';
import { CheckRoleUser } from 'src/utils/enum';
// icons
import iconNextDown from 'src/assets/icons/ic_next_down.svg';
// scss
import styles from './styles.modules.scss';

const DetailOrder = () => {
  // Util
  const { orderId } = useParams();
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const requestData = useSelector(selectRequestData);
  const isShowMessage = useSelector(selectIsShowMessage);
  const typeAction = useSelector(selectTypeAction);
  const message = useSelector(selectRequestMessage);
  const role = useSelector(selectAuthRole);
  const isEmployee = role === CheckRoleUser.EMPLOYEE;

  useEffect(() => {
    if (isShowMessage === true && typeAction === 'UPDATE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.UPDATE_ORDER.SUCCESS,
          function: () => {
            dispatch(requestActions.resetStatus());
            dispatch(requestActions.getDetailRequest({ _id: orderId }));
          },
        })
      );
    } else if (isShowMessage === false) {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            dispatch(requestActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, isShowMessage, message, typeAction]);

  useEffect(() => {
    dispatch(requestActions.getDetailRequest({ _id: orderId }));
  }, [orderId]);

  const handleClickOrderList = () => {
    if (!isEmployee) {
      navigate(`/${routerPaths.SUPER_ADMIN}/${routerPaths.ORDERS}/${routerPaths.LIST_ORDERS}`);
    } else {
      navigate(`/${routerPaths.EMPLOYEE}/${routerPaths.ORDERS}/${routerPaths.LIST_ORDERS}`);
    }
  };

  const handleClickCompleteOrder = () => {
    dispatch(
      digLogAction.getComFig({
        title: contentDialog.UPDATE_ORDER.CONFIRM,
        function: () => {
          dispatch(
            requestActions.updateRequest({ _id: requestData?._id, body: { status: FilterStatusRequest.keys.COMPLETE } })
          );
        },
        buttonTitle: {
          oke: 'Yes',
          cancel: 'No',
        },
      })
    );
  };

  return (
    <>
      <div className={`${cx('crHouseContainer')}`}>
        <div className={`${cx('rqOrderContent')}`}>
          <div className={cx('itHhItemRequest')}>
            {requestData?.status && (
              <>
                {requestData?.status === FilterStatusRequest.keys.INCOMPLETE ? (
                  <button className={cx('rqBntActive')}>Incomplete</button>
                ) : (
                  <button className={cx('rqBntIsActive')}>Complete</button>
                )}
              </>
            )}
          </div>

          <div className={cx('rqOrderHeader')}>
            <div className={cx('rqOrderInfo')}>
              <p className={cx('rqOrderTitle')}>Household Name</p>
              <p className={cx('rqOrderTitle', 'mobile')}>Household Name:</p>
              <p className={cx('rqOrderValue')}>
                {requestData?.infoHouseHold?.name?.firstName.concat(' ', requestData?.infoHouseHold?.name?.lastName) ||
                  'N/A'}
              </p>
            </div>

            <div className={cx('rqOrderInfo')}>
              <p className={cx('rqOrderTitle')}>Phone Number</p>
              <p className={cx('rqOrderTitle', 'mobile')}>Phone Number:</p>
              <p className={cx('rqOrderValue')}>{requestData?.infoHouseHold?.phoneNumber?.phone || 'N/A'}</p>
            </div>

            <div className={cx('rqOrderInfo')}>
              <p className={cx('rqOrderTitle')}>Address</p>
              <p className={cx('rqOrderTitle', 'mobile')}>Address:</p>
              <p className={cx('rqOrderValue')}>{requestData?.infoHouseHold?.address || 'N/A'}</p>
            </div>
          </div>

          <div className={cx('rqWorkOrder')}>
            <p className={cx('rqWorkOrderTitle')}>WO</p>
            <div className={cx('rqSubServices')}>
              {requestData?.services.map((value, index) => (
                <div div className={cx('rqSubServiceItem')}>
                  <div className={cx('rqSubServiceName')}>
                    {value.name} {Number.isFinite(value?.price) && value?.isActive && `(${value?.price}$)`}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={cx('rqOrderFooter')}>
            <p className={cx('rqOrderFooterTitle')}>Total Price ($)</p>
            {Number.isFinite(requestData?.total) && requestData?.total}
          </div>
        </div>

        <div className={cx('orderButtonContainer')}>
          <div className={cx('orderBackButtonWrapper')} onClick={handleClickOrderList}>
            <img alt='' src={iconNextDown} />
            <p>Order List</p>
          </div>

          {requestData?.status === FilterStatusRequest.keys.INCOMPLETE && (
            <button
              type='button'
              disabled={isEmployee}
              className={cx('formButton', `${isEmployee && 'disable'}`)}
              onClick={handleClickCompleteOrder}
            >
              Complete
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default DetailOrder;
