import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectAuthRole } from 'src/features/auth/authSlice';
// component
import InputField from 'src/components/inputField';
import TextareaField from 'src/components/textareaField';
// Utils
import { EMAIL_REGEX, PHONE_NUMBER_CANADA } from 'src/utils/constants';
import { formatPhoneNumber, handleErrorMessages } from 'src/utils/help';
import { contentDialog } from 'src/utils/contentDialog';
import { routerPaths } from 'src/utils/routers';
import { CheckRoleUser } from 'src/utils/enum';
// Others
import { digLogAction } from 'src/features/digLog/slice';
import {
  houseActions,
  selectHouseholdData,
  selectHouseholdLoading,
  selectHouseholdMessage,
  selectHouseholdShowMessage,
  selectHouseholdTypeAction,
} from 'src/features/superAdmin/household/slice';
// icons
import iconNextDown from 'src/assets/icons/ic_next_down.svg';
// scss
import styles from './styles.modules.scss';

const DetailHouseHoldForm = ({ houseHoldId }) => {
  // Util
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const loading = useSelector(selectHouseholdLoading);
  const isShowMessage = useSelector(selectHouseholdShowMessage);
  const message = useSelector(selectHouseholdMessage);
  const typeAction = useSelector(selectHouseholdTypeAction);
  const initHouseHoldData = useSelector(selectHouseholdData);
  const role = useSelector(selectAuthRole);
  const isEmployee = role === CheckRoleUser.EMPLOYEE;

  const [houseHoldData, setHouseholdData] = useState();
  const [isEnableButton, setIsEnableButton] = useState(false);

  useEffect(() => {
    if (initHouseHoldData) {
      const data = {
        firstName: initHouseHoldData?.name?.firstName,
        lastName: initHouseHoldData?.name?.lastName,
        address: initHouseHoldData?.address,
        phoneNumber: initHouseHoldData?.phoneNumber?.phone,
        email: initHouseHoldData?.email,
        houseInfo: initHouseHoldData?.houseInfo,
      };

      setValue('firstName', initHouseHoldData?.name?.firstName);
      setValue('lastName', initHouseHoldData?.name?.lastName);
      setValue('address', initHouseHoldData?.address);
      setValue('phoneNumber', initHouseHoldData?.phoneNumber?.phone);
      setValue('email', initHouseHoldData?.email);
      setValue('houseInfo', initHouseHoldData?.houseInfo);
      setHouseholdData(data);
    }
  }, [initHouseHoldData]);

  useEffect(() => {
    dispatch(houseActions.getDetailHousehold({ _id: houseHoldId }));
  }, [houseHoldId]);

  useEffect(() => {
    if (isShowMessage === true && typeAction === 'UPDATE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.UPDATE_HOUSEHOLD.SUCCESS,
          function: () => {
            dispatch(houseActions.resetStatus());
            setIsEnableButton(false);
          },
        })
      );
    } else if (isShowMessage === true && typeAction === 'DELETE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.DELETE_HOUSEHOLD.SUCCESS,
          function: () => {
            dispatch(houseActions.resetStatus());
            navigate(`/${routerPaths.SUPER_ADMIN}/${routerPaths.HOUSEHOLDS}/${routerPaths.LIST_HOUSE}`);
          },
        })
      );
    } else if (isShowMessage === false) {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            dispatch(houseActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, isShowMessage, message, typeAction]);

  const onChangeValue = (key, value) => {
    setIsEnableButton(true);
    setValue(key, value);
    setHouseholdData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubmitForm = (data) => {
    const newHouseholdData = {
      _id: houseHoldId,
      name: {
        firstName: data.firstName,
        lastName: data.lastName,
      },
      address: data.address,
      phoneNumber: {
        phone: data.phoneNumber,
      },
      email: data.email,
      houseInfo: data.houseInfo,
    };

    dispatch(houseActions.updateHousehold(newHouseholdData));
  };

  const handleClickCustomerList = () => {
    if (!isEmployee) {
      navigate(`/${routerPaths.SUPER_ADMIN}/${routerPaths.HOUSEHOLDS}/${routerPaths.LIST_HOUSE}`);
    } else {
      navigate(`/${routerPaths.EMPLOYEE}/${routerPaths.HOUSEHOLDS}/${routerPaths.LIST_HOUSE}`);
    }
  };

  const handleDeleteHouseHold = () => {
    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE_HOUSEHOLD.CLICK,
        function: () => {
          dispatch(houseActions.deleteHousehold({ _id: houseHoldId }));
        },
      })
    );
  };

  return (
    <>
      <div className={`${cx('crHouseContainer')}`}>
        <div className={cx('crContent')}>
          <div className={cx('crFormWrap')}>
            <form className={cx('crForm')}>
              <div className={cx('crFormContent')}>
                <InputField
                  label={'First Name*'}
                  classNameWrap={cx('crFormInput')}
                  classNameLabel={cx('crTitleInput')}
                  classNameInput={cx('crValueInput crFirstName')}
                  type={'text'}
                  register={register}
                  value={houseHoldData?.firstName}
                  name={'firstName'}
                  validation={{
                    required: 'First name is required',
                  }}
                  onChange={(e) => onChangeValue('firstName', e.target.value)}
                  error={errors['firstName']}
                  disabled={isEmployee}
                />

                <InputField
                  label={'Last Name*'}
                  classNameWrap={cx('crFormInput')}
                  classNameLabel={cx('crTitleInput')}
                  classNameInput={cx('crValueInput crLastName')}
                  type={'text'}
                  register={register}
                  value={houseHoldData?.lastName}
                  name={'lastName'}
                  validation={{
                    required: 'Last name is required',
                  }}
                  onChange={(e) => onChangeValue('lastName', e.target.value)}
                  error={errors['lastName']}
                  disabled={isEmployee}
                />

                <InputField
                  label={'Address*'}
                  classNameWrap={cx('crFormInput')}
                  classNameLabel={cx('crTitleInput')}
                  classNameInput={cx('crValueInput')}
                  type={'text'}
                  register={register}
                  value={houseHoldData?.address}
                  name={'address'}
                  validation={{
                    required: 'Address is required',
                  }}
                  onChange={(e) => onChangeValue('address', e.target.value)}
                  error={errors['address']}
                  disabled={isEmployee}
                />

                <InputField
                  label={'Phone Number*'}
                  classNameWrap={cx('crFormInput')}
                  classNameLabel={'crTitleInput'}
                  register={register}
                  value={houseHoldData?.phoneNumber}
                  name={'phoneNumber'}
                  type={'text'}
                  max={12}
                  classNameInput={cx('crValueInput')}
                  placeholder={'000-000-0000'}
                  onChange={(e) => {
                    onChangeValue('phoneNumber', `${formatPhoneNumber(e.target.value)}`);
                  }}
                  validation={{
                    required: 'Phone number is required',
                    pattern: {
                      value: PHONE_NUMBER_CANADA,
                      message: 'Phone Number format is not valid',
                    },
                  }}
                  error={errors['phoneNumber']}
                  disabled={isEmployee}
                />

                <InputField
                  label={'Email*'}
                  classNameWrap={cx('crFormInput')}
                  classNameLabel={cx('crTitleInput')}
                  classNameInput={cx('crValueInput')}
                  type={'email'}
                  register={register}
                  value={houseHoldData?.email}
                  name={'email'}
                  validation={{
                    required: 'Email is required',
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'Email format is not valid',
                    },
                  }}
                  onChange={(e) => onChangeValue('email', e.target.value)}
                  error={errors['email']}
                  disabled={isEmployee}
                />

                <TextareaField
                  label={'House Info'}
                  classNameWrap={cx('crFormTextarea')}
                  classNameLabel={cx('crTitleInput')}
                  classNameTextarea={`${cx('crValueTextarea')}`}
                  type={'text'}
                  register={register}
                  value={houseHoldData?.houseInfo}
                  name={'houseInfo'}
                  onChange={(e) => onChangeValue('houseInfo', e.target.value)}
                  disabled={isEmployee}
                />
              </div>

              <div className={cx('buttonContainer')}>
                <div className={cx('backButtonWrapper')} onClick={handleClickCustomerList}>
                  <img alt='' src={iconNextDown} />
                  <p>Customer List</p>
                </div>

                <div className={cx('actionButtonWrapper')}>
                  <button
                    type='button'
                    disabled={loading || isShowMessage || isEmployee}
                    className={cx('formButton', 'deleteButton', `${isEmployee && 'disable'}`)}
                    onClick={handleDeleteHouseHold}
                  >
                    Delete
                  </button>

                  <button
                    type='submit'
                    disabled={!isEnableButton}
                    className={cx('formButton', `${!isEnableButton && 'disable'}`)}
                    onClick={handleSubmit(handleSubmitForm)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailHouseHoldForm;
