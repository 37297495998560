import React from 'react';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';

//filter
import {
  digLogAction,
  selectDigLogTitle,
  selectDigLogComFig,
  selectDigLogFunction,
  selectDialogFunctionCancel,
  selectDialogButtonTitle,
} from 'src/features/digLog/slice';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const DigLogComFig = () => {
  const dispatch = useDispatch();
  /**
   * select
   */
  const title = useSelector(selectDigLogTitle);
  const showComFig = useSelector(selectDigLogComFig);
  const handleFunction = useSelector(selectDigLogFunction);
  const handleFunctionCancel = useSelector(selectDialogFunctionCancel);
  const buttonTitle = useSelector(selectDialogButtonTitle);

  const resetDigLog = () => {
    handleFunctionCancel && handleFunctionCancel();
    dispatch(digLogAction.resetState());
  };

  const handleDelete = () => {
    handleFunction && handleFunction();
    dispatch(digLogAction.resetState());
  };

  return (
    <div>
      {showComFig ? (
        <>
          <div className={cx('dlWrapper')}></div>
          <div className={cx('dbDigLog')}>
            <div className={cx('dbBlock')}>
              <div>
                <p className={cx('dlTitle')}>{title}</p>
              </div>
              <div>
                <button className={cx('dlBntCancel')} onClick={resetDigLog}>
                  {buttonTitle?.cancel || 'Cancel'}
                </button>
                <button className={cx('dlBnt')} onClick={handleDelete}>
                  {buttonTitle?.oke || 'OK'}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DigLogComFig;
