// Library
import classNames from 'classnames/bind';
import { Validator } from 'devextreme-react';
import { PatternRule, RequiredRule } from 'devextreme-react/data-grid';
import { Button as TextBoxButton, TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// Components
// Features
// Utils

// Icons
import EyeHideIcon from 'src/assets/icons/ic_eye_hide.svg';
import EyeShowIcon from 'src/assets/icons/ic_eye_show.svg';
import { authActions, selectAuthMessage, selectAuthStatus } from 'src/features/auth/authSlice';
import { digLogAction } from 'src/features/digLog/slice';
import { contentDialog } from 'src/utils/contentDialog';
// Images
// Scss
import styles from './styles.module.scss';
import { PASSWORD_REGEX } from 'src/utils/constants';
import { handleErrorMessages } from 'src/utils/help';
import { exactRouter } from 'src/utils/routers';

const cx = classNames.bind(styles);

function CreateNewPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = qs.parse(location.search);
  const isMessage = useSelector(selectAuthStatus);
  const message = useSelector(selectAuthMessage);

  /**
   * UseState Block
   */
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordMode, setPasswordMode] = useState('password');
  const [confirmPasswordMode, setConfirmPasswordMode] = useState('password');
  const [eyeIconPassword, setEyeIconPassword] = useState(EyeHideIcon);
  const [eyeIconConfirmPassword, setEyeIconConfirmPassword] = useState(EyeHideIcon);

  /**
   * Function Handle
   */
  const passwordButton = {
    icon: eyeIconPassword,
    stylingMode: 'text',
    onClick: () => {
      setPasswordMode(passwordMode === 'text' ? 'password' : 'text');
      setEyeIconPassword(eyeIconPassword === EyeHideIcon ? EyeShowIcon : EyeHideIcon);
    },
  };

  const confirmPasswordButton = {
    icon: eyeIconConfirmPassword,
    stylingMode: 'text',
    onClick: () => {
      setConfirmPasswordMode(confirmPasswordMode === 'text' ? 'password' : 'text');
      setEyeIconConfirmPassword(eyeIconConfirmPassword === EyeHideIcon ? EyeShowIcon : EyeHideIcon);
    },
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.value);
  };

  const handlePasswordConfirm = (e) => {
    setPasswordConfirm(e.value);
  };

  const handleOnSubmit = (e) => {
    if (newPassword === null || passwordConfirm === '') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.CREATE_NEW_PASSWORD.NONE,
        })
      );
    }

    e.preventDefault();
    const body = {
      password: newPassword,
      token: token,
    };

    if (newPassword && passwordConfirm && newPassword === passwordConfirm) {
      dispatch(authActions.createNewPassword({ body, navigate }));
    } else {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.CREATE_NEW_PASSWORD.FAILED,
        })
      );
      // setNewPassword('');
      // setPasswordConfirm('');
    }
  };

  useEffect(() => {
    if (isMessage === true) {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.CREATE_NEW_PASSWORD.SUCCESS,
          function: () => {
            window.location.reload();
            dispatch(authActions.resetStatus());
          },
        })
      );
    }

    if (isMessage === false) {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            navigate(`${exactRouter.forgotPassword}`);
            dispatch(authActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, isMessage, message, navigate]);

  return (
    <div className={cx('createNewPassword')} id='createNewPassword'>
      <div className={cx('cnpTitle')}>
        <div className={cx('cnpText')}>Create new password</div>
        <div className={cx('cnpDesc')}>
          <Link to={`${exactRouter.forgotPassword}`} className={cx('lg-forgot')}>
            Forgot Password
          </Link>
        </div>
      </div>

      <form className={cx('cnpForm')} onSubmit={handleOnSubmit}>
        <div className={cx('cnpField')}>
          <p className={cx('cnpRequired')}>Password*</p>
          <TextBox
            mode={passwordMode}
            showClearButton={true}
            className={cx('cnpInput')}
            value={newPassword}
            onValueChanged={handlePasswordChange}
          >
            <Validator>
              <PatternRule
                pattern={PASSWORD_REGEX}
                message='Password must be at least 8 characters and contain at least 1 uppercase character, 1 number, and 1 special character.'
              />
            </Validator>
            <TextBoxButton id='abc' name='password' location='after' options={passwordButton} />
          </TextBox>
        </div>
        <div className={cx('cnpField')}>
          <p className={cx('cnpRequired')}>Confirm password*</p>
          <TextBox
            mode={confirmPasswordMode}
            className={cx('cnpInput')}
            value={passwordConfirm}
            onValueChanged={handlePasswordConfirm}
          >
            <Validator>
              <PatternRule
                pattern={PASSWORD_REGEX}
                message='Password must be at least 8 characters and contain at least 1 uppercase character, 1 number, and 1 special character.'
              />
            </Validator>
            <TextBoxButton id='abc' name='password' location='after' options={confirmPasswordButton} />
          </TextBox>
        </div>

        <Button
          text='Reset and log in'
          activeStateEnabled={false}
          focusStateEnabled={false}
          type='success'
          className={cx('cnpButton')}
          useSubmitBehavior={true}
        />
      </form>
    </div>
  );
}

export default CreateNewPassword;
