//Library
import classNames from 'classnames/bind';
import React from 'react';
import { Navigate, NavLink, useLocation, useNavigate } from 'react-router-dom';
import packageJson from '../../../../package.json';

// Icon
import iconLogo from 'src/assets/icons/ic_logo_sidebar.svg';
import iconHumbugger from 'src/assets/icons/ic_humbugger.svg';

// Components

// Features

// Utils
import { exactRouter } from 'src/utils/routers';

// Scss
import styles from './styles.module.scss';
import { menu } from 'src/utils/enum';

const cx = classNames.bind(styles);

const Sidebar = ({ showSideBar, setShowSideBar }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const version = packageJson.version;

  // Check active icon menu
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const handleLogoClick = () => {
    navigate('/');
    setShowSideBar(false);
  };

  return (
    <>
      <aside className={`${cx('sbWrapper')} ${showSideBar ? cx('sbShowSideBar') : cx('')}`}>
        <div className={cx('sbLogo')} onClick={handleLogoClick}>
          <img src={iconLogo} className={cx('sbLogoImg')} alt='iconLogo' />
          <div className={cx('right-logo')}>
            <h4 className={cx('top-logo')}>TADA</h4>
            {/* <h5 className={cx('bottom-logo')}>Home Services Inc.</h5> */}
          </div>
        </div>
        <ul className='sbMenu'>
          {menu.map((item, idx) => {
            return (
              <NavLink
                key={idx}
                className={({ isActive }) => (isActive ? cx('active') : cx('not-active'))}
                to={`${item.PATH}`}
              >
                <li className={cx('li')} onClick={() => setShowSideBar(false)}>
                  <img
                    src={activeRoute(`${item.PATH}`) ? item.ICON_ACTIVE : item.ICON}
                    className={cx('img-li')}
                    alt='icon'
                  />
                  <span>{item.NAME}</span>
                </li>
              </NavLink>
            );
          })}
        </ul>

        <span className={cx('sbVersion')}>Version: {version}</span>
        {!showSideBar && (
          <div className={cx('sbHumbugger')} onClick={() => setShowSideBar(!showSideBar)}>
            <img src={iconHumbugger} alt='humbugger' className={cx('sbHumbuggerIcon')} />
          </div>
        )}
      </aside>
      {showSideBar && <div className={cx('sbOverlay')} onClick={() => setShowSideBar(false)}></div>}
    </>
  );
};

export default Sidebar;
