import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Slice
import { digLogAction } from 'src/features/digLog/slice';
import {
  combineServiceActions,
  selectCombineServiceIsSuccess,
  selectCombineServiceMessage,
  selectCombineServiceShowMessage,
  selectCombineServiceTypeAction,
} from 'src/features/superAdmin/combineService/slice';

// Utils
import { useWidthSize } from 'src/utils/help';
import { handleErrorMessages } from 'src/utils/help';
// scss
import styles from './styles.module.scss';

// icons
import iconDetailsDown from 'src/assets/icons/ic_down.svg';
import iconEdit from 'src/assets/icons/ic_edit.svg';
import iconDelete from 'src/assets/icons/ic_delete.svg';
import iconDetailsUp from 'src/assets/icons/ic_top.svg';
import UpdateCombineService from '../../../update';
import iconDeleteBox from 'src/assets/icons/ic_delete_box.svg';
import iconStatus from 'src/assets/icons/ic_check_box.svg';
import { contentDialog } from 'src/utils/contentDialog';

const TableItemCombineService = (props) => {
  const { combineService, isClicked, index, handleToggleAccordion } = props;
  const screenWidth = useWidthSize();

  // Utils
  const cx = classNames.bind(styles);
  const dispatch = useDispatch();

  // Selector
  const isSuccess = useSelector(selectCombineServiceIsSuccess);
  const message = useSelector(selectCombineServiceMessage);
  const isShowMessage = useSelector(selectCombineServiceShowMessage);
  const typeAction = useSelector(selectCombineServiceTypeAction);
  /**
   * Set state
   */
  const [showCombine, setShowCombine] = useState(false);
  const [combineServiceEdit, setCombineServiceEdit] = useState();

  // useEffect handle when update combine service
  useEffect(() => {
    if (isSuccess) {
      setShowCombine(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isShowMessage === true && typeAction === 'CREATE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.CREATE_COMBINE_SERVICE.SUCCESS,
          function: () => {
            //dispatch(combineServiceActions.resetStatus());
          },
        })
      );
    } else if (isShowMessage === true && typeAction === 'UPDATE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.UPDATE_COMBINE_SERVICE.SUCCESS,
          function: () => {
            dispatch(combineServiceActions.resetStatus());
            dispatch(combineServiceActions.getAllCombineService());
            if (!handleToggleAccordion) return;
            handleToggleAccordion(null);
          },
        })
      );
    } else if (isShowMessage === true && typeAction === 'DELETE') {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.DELETE_COMBINE_SERVICE.SUCCESS,
          function: () => {
            dispatch(combineServiceActions.resetStatus());
            dispatch(combineServiceActions.getAllCombineService());
            if (!handleToggleAccordion) return;
            handleToggleAccordion(null);
          },
        })
      );
    } else if (isShowMessage === false) {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            dispatch(combineServiceActions.resetStatus());
            if (!handleToggleAccordion) return;
            handleToggleAccordion(null);
          },
        })
      );
    }
  }, [dispatch, handleToggleAccordion, isShowMessage, message, typeAction]);

  // useEffect(() => {
  //   dispatch(combineServiceActions.getAllCombineService());
  // }, [isDelete]);

  const editCombine = () => {
    setShowCombine(!showCombine);
    setCombineServiceEdit(combineService);
  };

  const deleteCombine = () => {
    const id = combineService._id;

    dispatch(
      digLogAction.getComFig({
        title: contentDialog.DELETE.CLICK,
        function: () => {
          if (id) {
            dispatch(combineServiceActions.deleteCombineService(id));
          }
        },
        functionCancel: () => {
          dispatch(digLogAction.resetState());
        },
      })
    );
  };

  // Handle resize screen
  useEffect(() => {
    if (screenWidth >= 768) {
      if (!handleToggleAccordion) return;
      handleToggleAccordion(null);
    }
  }, [handleToggleAccordion, screenWidth]);

  return (
    <>
      {isClicked === index ? (
        <div className={cx('itCbDetails')}>
          <UpdateCombineService
            showCombine={showCombine}
            setShowCombine={setShowCombine}
            combineServiceEdit={combineServiceEdit}
          />
          <div className={cx('itCbDetailsActions')}>
            <div className={cx('itCbDetailsEdit')} onClick={editCombine}>
              <img src={iconEdit} alt='editIcon' className={cx('cbIconItem')} />
            </div>

            <div className={cx('itCbDetailsDelete')} onClick={deleteCombine}>
              <img src={iconDelete} alt='deleteIcon' className={cx('cbIconItem')} />
            </div>
            <img
              src={iconDetailsUp}
              alt='details'
              className={cx('itCbDetailsIcon')}
              onClick={() => {
                if (!handleToggleAccordion) return;
                handleToggleAccordion(null);
              }}
            />
          </div>
          <p title={combineService.name} className={cx('cbCbDetailsItem')}>
            {combineService.name}
          </p>
          <p className={cx('cbCbDetailsItem')}>{combineService.offer}</p>

          <div
            className={cx(`cbCbDetailsItem  ${combineService.isActive ? cx('detailStatus') : cx('detailNotStatus')}`)}
          >
            {combineService.isActive ? (
              <img src={iconStatus} className={cx('icon')} alt='icon' />
            ) : (
              <img src={iconDeleteBox} className={cx('iconBox')} alt='icon' />
            )}
          </div>
        </div>
      ) : (
        <div className={cx('cbTableItem')}>
          <UpdateCombineService
            showCombine={showCombine}
            setShowCombine={setShowCombine}
            combineServiceEdit={combineServiceEdit}
          />
          <p title={combineService.name} className={cx('cbItem')}>
            {combineService.name}
          </p>
          <p className={`${cx('cbItem')} ${cx('cbOffer')}`}>{combineService.offer}</p>
          <div className={cx(`cbItem cbStatus  ${combineService.isActive ? cx('status') : cx('notStatus')}`)}>
            {combineService.isActive ? (
              <img src={iconStatus} className={cx('icon')} alt='icon' />
            ) : (
              <img src={iconDeleteBox} className={cx('iconBox')} alt='icon' />
            )}
          </div>

          <div className={cx('itCbItemCombineService')}>
            <div className={cx('itCbBtnGroupEdit')} onClick={editCombine}>
              <img src={iconEdit} alt='editIcon' className={cx('cbIconItem')} />
            </div>

            <div className={cx('itCbBtnGroupDelete')} onClick={deleteCombine}>
              <img src={iconDelete} alt='deleteIcon' className={cx('cbIconItem')} />
            </div>
            <img
              src={iconDetailsDown}
              alt='details'
              className={cx('itCbDetailsIcon')}
              onClick={() => {
                if (!handleToggleAccordion) return;
                handleToggleAccordion(index);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TableItemCombineService;
