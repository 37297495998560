// Library
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';

import { useLocation } from 'react-router-dom';

// Components

// Features
import { authActions, selectAuthMessage, selectAuthStatus } from 'src/features/auth/authSlice';
import { digLogAction } from 'src/features/digLog/slice';
// Utils
import { contentDialog } from 'src/utils/contentDialog';
import { handleErrorMessages } from 'src/utils/help';
// Icons

// Images

// Scss
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

function CheckMail() {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const isSuccess = useSelector(selectAuthStatus);
  const message = useSelector(selectAuthMessage);
  /**
   * Function Handle
   */

  const onHandleResend = () => {
    if (state) {
      dispatch(authActions.forgotPassword(state));
    }
  };

  /**
   * UseEffect Block
   */
  useEffect(() => {
    if (isSuccess === true) {
      dispatch(
        digLogAction.getTitle({
          title: contentDialog.FORGOT_PASSWORD.SUCCESS,
          function: () => {
            dispatch(authActions.resetStatus());
          },
        })
      );
    } else if (isSuccess === false) {
      dispatch(
        digLogAction.getTitle({
          title: handleErrorMessages(message),
          function: () => {
            dispatch(authActions.resetStatus());
          },
        })
      );
    }
  }, [dispatch, isSuccess, message]);

  return (
    <div className={cx('checkMail')} id='checkMail'>
      <div className={cx('cmTitle')}>
        <div className={cx('cmText')}>Check your email</div>
        <div className={cx('cmDesc')}>We have sent a link to reset your password. Please check your inbox</div>
      </div>

      <button
        type='submit'
        className={cx('cmButton')}
        onClick={() => window.open('https://mail.google.com/', '_blank')}
      >
        Open Email App
      </button>

      <div className={cx('fgResend')}>
        Did not receive the link?
        <button className={cx('cmBtnResend')} onClick={onHandleResend}>
          Resend
        </button>
      </div>
    </div>
  );
}

export default CheckMail;
