import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// component
import { selectHouseholdLoading, selectHouseholdShowMessage } from 'src/features/superAdmin/household/slice';
import DetailHouseHoldForm from './form/detailHouseHold';
import HouseHoldServicesForm from './form/service';
import TabBar from 'src/components/common/tabBar/TabBar';
// Utils
import { routerPaths } from 'src/utils/routers';
// scss
import styles from './styles.modules.scss';

const DetailHouseHold = () => {
  // Util
  const { houseHoldId } = useParams();
  const location = useLocation();
  const cx = classNames.bind(styles);

  const [tabData, setTabData] = useState([
    {
      key: routerPaths.DETAIL_HOUSEHOLD,
      title: 'Detail',
      component: <DetailHouseHoldForm houseHoldId={houseHoldId} />,
    },
    {
      key: routerPaths.SERVICES,
      title: 'Services',
      component: <HouseHoldServicesForm houseHoldId={houseHoldId} />,
    },
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');
    if (tab === routerPaths.SERVICES) {
      setTabData((prevData) => prevData.map((e) => ({ ...e, active: e.key === tab })));
    }
  }, [location]);

  return (
    <>
      <div className={`${cx('crHouseContainer')}`}>
        <TabBar tabData={tabData} />
      </div>
    </>
  );
};

export default DetailHouseHold;
