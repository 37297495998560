import classNames from 'classnames/bind';
import React from 'react';

// scss
import styles from './styles.module.scss';

const TableHeaderCombineService = () => {
  const cx = classNames.bind(styles);
  return (
    <div>
      <div id='cbHederCombineService' className={cx('cbHederCombineService')}>
        <div className={cx('cbHead')}>Services</div>
        <div className={cx('cbOffer')}>Offer (%)</div>
        <div className={cx('cbStatus')}>Status</div>
        <div className={cx('cbAction')}>Action</div>
      </div>
    </div>
  );
};

export default TableHeaderCombineService;
