export const baseURL = process.env.REACT_APP_BASE_URL;
export const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
export const socketURL = process.env.REACT_APP_SOCKET_URL;

export const EventType = {
  EVENT_CONNECTION: 'connection',
  EVENT_CONNECT_ERROR: 'connect_error',
  EVENT_DISCONNECT: 'disconnect',
  EVENT_DISCONNECTING: 'disconnecting',
};

export const CreateHouseHoldStep = {
  CREATE_HOUSEHOLD: 'create_household',
  LIST_SERVICE: 'list_service',
  LIST_SUB_SERVICE: 'list_sub_service',
};

export const EventHousehold = {
  CONNECT_HOUSEHOLD: 'CONNECT_HOUSEHOLD',
  HOUSEHOLD: 'HOUSEHOLD',
};

export const authUrl = {
  auth: 'auth',
  login: 'login',
  forgotPassword: 'forgot-password',
  createNewPassword: 'create-new-password',
};

//#region Endpoint Api
export const serviceUrl = {
  getAllServiceWithoutParams: 'service-groups/groups',
  getAllService: 'service-groups',
  postService: 'service-groups',
  deleteService: 'service-groups',
  putService: 'service-groups',
  detailService: 'service-groups',
};

export const subServiceUrl = {
  getAllSubService: 'services',
  postSubService: 'v2/services',
  deleteSubService: 'services',
  putSubService: 'v2/services',
  basic: 'services',
};

export const combineServiceUrl = {
  basic: '/offers',
  getAllCombineService: '/offers/all',
};

export const householdUrl = {
  getAllHouseHold: 'households',
};

export const requestUrl = {
  basic: '/requests',
  getAllRequest: 'requests',
  calendarAvailable: 'calendar-available',
};

export const userManagementApi = {
  getUserManagement: 'staffs',
};

export const fileUrl = {
  uploadFile: 'files/upload',
};
//#endregion Endpoint Api

export const provinces = ['PE', 'NS', 'NB', 'QC', 'ON', 'MB', 'SK', 'AB', 'BC', 'YT'];

// Regex
export const POSTAL_CODE_CANADA = /^[a-zA-Z]\d[a-zA-Z][ -]?\d[a-zA-Z]\d$/i;
// export const PHONE_NUMBER_CANADA = /^(?=.*\d).{12,}$/;
export const PHONE_NUMBER_CANADA = /^(?!0+-0+-0+$)(?=.*\d)[\d-]{12,}$/;
// export const POSTAL_CODE_CANADA = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
export const TEXT_REGEX = /^[a-zA-Z]*$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const NUMBER_REGEX = /[^\d]/g;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/;
// export const OFFER_PERCENT_REGEX = /^[1-9][0-9]?$|^100$/;
export const OFFER_PERCENT_REGEX = /^[0-9]*[1-9][0-9]*$/;
export const OFFER_PERCENT_REGEX1 = /^[0-9]*[.]*[0-9]*$/;
export const IMAGE_TYPE_REGEX = /\.(jpg|jpeg|png|gif)$/;

export const TIME_OPTIONS = [
  '08:00 AM - 10:00 AM',
  '08:15 AM - 10:15 AM',
  '08:30 AM - 10:30 AM',
  '08:45 AM - 10:45 AM',
  '09:00 AM - 11:00 AM',
];

export const MAX_SERVICE_PER_HOUSEHOLD = 30;
export const MAX_FILE_SIZE = 5 * 1024 * 1024;
export const CONTENT_TYPE_FORM_DATA = 'multipart/form-data';

// Strings
export const ACCOUNT_NOT_SUPPORT = 'This account is not supported for login';
export const NOT_PERMISSION_DELETE = 'No permission to delete';
export const IMAGE_REQUIRED = 'Image is required';
export const SERVICE_NAME_REQUIRED = 'Service name is required';
export const SUBSERVICE_NAME_REQUIRED = 'Sub-Service name is required';
export const WRONG_PRICE_FORMAT = 'Price must be a number';
