import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import serviceReducer from 'src/features/superAdmin/service/slice';
import subServiceReducer from 'src/features/superAdmin/subService/slice';
import digLogReducer from 'src/features/digLog/slice';
import userManagementReducer from 'src/features/superAdmin/userManagement/slice';
import householdReduce from 'src/features/superAdmin/household/slice';
import combineServiceReducer from 'src/features/superAdmin/combineService/slice';
import rootSaga from './rootSaga';
import authReducer from 'src/features/auth/authSlice';
import requestReducer from 'src/features/superAdmin/requests/slice';
const rootReducer = combineReducers({
  service: serviceReducer,
  subService: subServiceReducer,
  digLog: digLogReducer,
  userManagement: userManagementReducer,
  household: householdReduce,
  request: requestReducer,
  combineService: combineServiceReducer,
  auth: authReducer,
});

const sagaMiddleware = createSagaMiddleware();

const configStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
  });

  sagaMiddleware.run(rootSaga);
  return store;
};

const store = configStore();
export default store;
