import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  listUserManagement: [],
  isSuccess: false,
  checkShow: false,
  total: null,
  paginationTotal: null,
  isShowMessage: null,
  typeAction: '',
  message: '',
  filter: {
    page: 1,
    limit: 10,
    isOther: null,
    textSearch: '',
  },
};

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    //get all user
    getAllUserManagement(state, action) {
      state.loading = true;
      state.isSuccess = false;
    },
    getAllUserManagementSuccess(state, action) {
      state.loading = false;
      state.listUserManagement = action.payload.staffs;
      state.total = action.payload.total;
      state.paginationTotal = action.payload.pagination.total;
    },
    getAllUserManagementFailed(state, action) {
      state.loading = false;
    },

    //create user
    createUser(state, action) {
      state.loading = true;
      state.isSuccess = false;
    },
    createUserSuccess(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.checkShow = false;
      state.isShowMessage = true;
      state.typeAction = 'CREATE';
    },
    createUserFailed(state, action) {
      state.loading = false;
      state.isSuccess = false;
      state.checkShow = true;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },
    //update user
    updateUser(state, action) {
      state.loading = true;
      state.isSuccess = false;
    },
    updateUserSuccess(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.checkShow = false;
      state.isShowMessage = true;
      state.typeAction = 'UPDATE';
    },
    updateUserFailed(state, action) {
      state.loading = false;
      state.isSuccess = false;
      state.checkShow = true;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
    },
    //delete user
    deleteUser(state, action) {
      state.loading = true;
      state.isSuccess = false;
    },
    deleteUserSuccess(state, action) {
      state.loading = false;
      state.isSuccess = true;
      state.isShowMessage = true;
      state.typeAction = 'DELETE';
      state.checkShow = false;
    },
    deleteUserFailed(state, action) {
      state.loading = false;
      state.isSuccess = false;
      state.isShowMessage = false;
      state.typeAction = '';
      state.message = action.payload;
      state.checkShow = false;
    },
    //filter
    filter(state, actions) {
      state.filter = actions.payload;
    },
    //reset
    resetState: () => initialState,
    resetStatus: (state) => {
      state.message = '';
      state.isShowMessage = null;
      state.typeAction = '';
    },
  },
});
export const userManagementAction = userManagementSlice.actions;

export const selectUserList = (state) => state.userManagement.listUserManagement;
export const selectUserTotal = (state) => state.userManagement.total;
export const selectUserPaginationTotal = (state) => state.userManagement.paginationTotal;
export const selectUserIsSuccess = (state) => state.userManagement.isSuccess;
export const selectUserCheckShow = (state) => state.userManagement.checkShow;
export const selectUserFilter = (state) => state.userManagement.filter;
export const selectUserLoading = (state) => state.userManagement.loading;
export const selectUserTypeAction = (state) => state.userManagement.typeAction;
export const selectUserMessage = (state) => state.userManagement.message;
export const selectUserShowMessage = (state) => state.userManagement.isShowMessage;

const userManagementReducer = userManagementSlice.reducer;
export default userManagementReducer;
