import classNames from 'classnames/bind';
import { useRef, useState } from 'react';
//import icon
import downIcon from 'src/assets/icons/ic_down.svg';
import upIcon from 'src/assets/icons/ic_top.svg';
import helpFunction from 'src/utils/help';
//scss
import styles from './styles.module.scss';
const cx = classNames.bind(styles);

const Filter = (props) => {
  const { Category, selected, setSelected, filter, handleChangeFilter, className, classCombine } = props;
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  //onblur dropdown
  const filterDropdownRef = useRef(null);

  const handleOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  helpFunction.useOutside(filterDropdownRef, setIsOpenFilter);

  return (
    <div
      id='ftFilter'
      className={classCombine ? cx('ftFilterWrapperCombine') : cx('ftFilterWrapper')}
      ref={filterDropdownRef}
    >
      <div
        className={
          className ? cx('ftOverflowHiddenMin') : classCombine ? cx('ftOverflowHiddenCombine') : cx('ftOverflowHidden')
        }
        onClick={handleOpenFilter}
      >
        <button className={className ? cx('ftFilterWrapperMin') : cx('ftFilterBtn')}>
          <span className={cx('ftBlock')}>
            {Category && `${Category}: `} {filter[selected] || Object.values(filter)[0]}
          </span>
          <span className={cx('ftHeaderIcon')}>
            <img
              src={!isOpenFilter ? downIcon : upIcon}
              alt='arrowDownIcon'
              className={isOpenFilter ? cx('ftIconDownUp') : cx('ftIconDownUp')}
            />
          </span>
        </button>
      </div>
      {isOpenFilter && (
        <ul className={classCombine ? cx('ftFilterDropdownWrapperCombine') : cx('ftFilterDropdownWrapper')}>
          {Object.keys(filter).map((key, idx) => (
            <li
              className={`${cx('ftFilterDropdownItem')} ${cx('ftFilterDropdownItemBorder')} ${
                selected === key && cx('ftFilterDropdownSelected')
              }`}
              key={idx}
              onClick={(e) => {
                setSelected(key);
                setIsOpenFilter(false);
                if (typeof handleChangeFilter === 'function') handleChangeFilter(key);
              }}
            >
              {filter[key]}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Filter;
